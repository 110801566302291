// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AuthService from '../../../services/auth.service'
import DataService from '../../../services/data.service'
import { useDispatch } from 'react-redux'
import logo from '../../../assets/Moowplay_logo.svg'

export const Register = () => {
  const [loading, setLoading] = useState(false)
  const [matchCompany, setMatchCompany] = useState([])
  const [selectedComp, setSelectedComp] = useState()
  const [dialog, setDialog] = useState(false)
  const [msg, setMsg] = useState('')
  const dispatch = useDispatch()

  const [companyList, setCompanyList] = useState([])
  useEffect(() => {
    DataService.getCompanyList().then(res => {
      setCompanyList(res)
    })
    dispatch({ type: 'CHANGE_PATH', payload: '/register' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function queryCompany(taxId) {
    const matches = companyList.filter(ele => {
      return ele.TaxId === taxId
    })
    if (matches.length > 0) {
      setMatchCompany(matches)
      return matches
    } else {
      return null
    }
  }

  const [taxid, setTaxid] = useState()
  function taxidCallback(e) {
    if (!(/^[0-9]*$/gm.exec(e.target.value))) {
      e.target.value = taxid ? taxid : null
      return
    }
    setTaxid(e.target.value)

    const company = queryCompany(e.target.value)
    if (company) {
      if (company.length === 1) {
        document.querySelector('.company').value = company[0].CompanyName
        document.querySelector('.company').classList.remove('alert')
        document.querySelector('.company').classList.add('pass')
        setSelectedComp(company[0].Id)
      }
      e.target.classList.remove('alert')
      e.target.classList.add('pass')
      document.querySelector('.tax-id-alert').classList.remove('show')
      document.querySelector('.tax-id-alert').innerHTML = '.'
    } else if (e.target.value.length === 13) {
      document.querySelector('.company').value = 'ไม่พบบริษัทของท่านในระบบ'
      document.querySelector('.company').classList.add('alert')
      document.querySelector('.company').classList.remove('pass')
      e.target.classList.remove('pass')
      document.querySelector('.tax-id-alert').classList.add('show')
      document.querySelector('.tax-id-alert').innerHTML = 'ไม่พบบริษัทของท่านในระบบ'
    } else {
      document.querySelector('.company').value = ''
      document.querySelector('.company').classList.remove('alert')
      document.querySelector('.company').classList.remove('pass')
      if (e.target.value.length !== 0) {
        e.target.classList.add('alert')
        document.querySelector('.tax-id-alert').classList.add('show')
        document.querySelector('.tax-id-alert').innerHTML = 'กรุณากรอกหมายเลขให้ครบ 13 หลัก'
      } else {
        setMatchCompany([])
        e.target.classList.remove('alert')
        document.querySelector('.tax-id-alert').classList.remove('show')
        document.querySelector('.tax-id-alert').innerHTML = '.'
      }
      e.target.classList.remove('pass')
    }
  }

  function selectCheck() {
    if (document.querySelector('.company').value !== "") {
      document.querySelector('.company').classList.remove('alert')
      document.querySelector('.company').classList.add('pass')
      setSelectedComp(document.querySelector('.company').value)
    }
    else {
      document.querySelector('.company').classList.remove('pass')
      document.querySelector('.company').classList.add('alert')
    }
  }

  const [email, setEmail] = useState()
  function emailCallback(e) {
    setEmail(e.target.value)
    if (/\S+@\S+\.\S+/.test(e.target.value) || e.target.value.length === 0) {
      alert('.email', '.', false)
      e.target.value.length !== 0 ? document.querySelector('.email').classList.add('pass') : document.querySelector('.email').classList.remove('pass')
    } else {
      alert('.email', 'กรุณากรอกอีเมลที่ถูกต้อง')
      document.querySelector('.email').classList.remove('pass')
    }
  }

  const [password, setPassword] = useState()
  function passwordCallback(e) {
    setPassword(e.target.value)
    if (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>_+-]{6,}$/.test(e.target.value) || e.target.value.length === 0) {
      alert('.password', '.', false)
      e.target.value.length !== 0 ? document.querySelector('.password').classList.add('pass') : document.querySelector('.password').classList.remove('pass')
    } else {
      alert('.password', 'ภาษาอังกฤษ ตัวเลข และอักขระพิเศษ ตั้งแต่ 6 ตัวขึ้นไป')
      document.querySelector('.password').classList.remove('pass')
    }
  }

  const [confirmPassword, setConfirmPassword] = useState()
  function confirmPasswordCallback(e) {
    setConfirmPassword(e.target.value)
    if ((validate('.password') && password === e.target.value) || e.target.value.length === 0) {
      alert('.confirm-password', '.', false)
      e.target.value.length !== 0 ? document.querySelector('.confirm-password').classList.add('pass') : document.querySelector('.confirm-password').classList.remove('pass')
    } else {
      alert('.confirm-password', 'รหัสผ่านไม่ตรงกัน')
      document.querySelector('.confirm-password').classList.remove('pass')
    }
  }

  function registerCallback(e) {
    selectCheck()
    if (validate('.tax-id') && validate('.company') && validate('.email') && validate('.password') && validate('.confirm-password')) {
      setLoading(true)
      AuthService.register({
        Email: email,
        Password: password,
        CompanyId: selectedComp
      }).then(res => {
        console.log(res)
        setLoading(false)
        if (res.data.msg === 'อีเมลนี้ถูกใช้งานไปแล้ว') {
          alert('.email', res.data.msg)
          document.querySelector('.email').classList.remove('pass')
        } else if (res.error) {
          setDialog(true)
          setMsg(res.data.msg)
        }
        else {
          setDialog(true)
          setMsg('สมัครสมาชิกสำเร็จ กรุณารออีเมลตอบกลับจากผู้ดูแลจึงจะสามารถเข้าสู่ระบบได้')
        }
      })
    }
    else {
      if (!validate('.tax-id')) {
        promptEmpty('.tax-id')
      }
      if (!validate('.email')) {
        promptEmpty('.email')
      }
      if (!validate('.password')) {
        promptEmpty('.password')
      }
      if (!validate('.confirm-password')) {
        promptEmpty('.confirm-password')
      }
    }

    e.preventDefault()
  }

  function validate(identifier) {
    const ref = document.querySelector(identifier)
    return ref.classList.contains('pass')
  }

  function promptEmpty(identifier) {
    const ref = document.querySelector(identifier)
    if (!ref.classList.contains('alert')) {
      ref.classList.add('alert')
      //Set alert msg to กรุณากรอกข้อมูล
      document.querySelector(identifier + '-alert').classList.add('show')
      document.querySelector(identifier + '-alert').innerHTML = 'กรุณากรอกข้อมูล'
    }
  }

  function alert(identifier, message = '.', on = true) {
    const boxRef = document.querySelector(identifier)
    const msgRef = document.querySelector(identifier + '-alert')
    if (on) {
      boxRef.classList.add('alert')
      msgRef.classList.add('show')
      msgRef.innerHTML = message
    } else {
      boxRef.classList.remove('alert')
      msgRef.classList.remove('show')
      msgRef.innerHTML = message
    }
  }

  return (
    <div className="Register">
      <div className="bgWrapper"></div>
      <div className="container">
        <div className="wrapper">
          <img className="noselect" src={logo} alt="MOOWPLAY" />
          <h3 className="sub-title noselect">สมัครสมาชิก</h3>
          <p className="tax-id-alert">.</p>
          <input autoFocus className="tax-id" type="text" onChange={taxidCallback} placeholder="เลขประจำตัวผู้เสียภาษีบริษัท" maxLength="13" title="เลขประจำตัวผู้เสียภาษี 13 หลัก"></input>
          <p className="company-alert">.</p>
          {(matchCompany.length > 1) ? (
            <select id="company" className="company" onChange={selectCheck}>
              <option value="" disabled selected>กรุณาเลือกสาขาของท่าน...</option>
              {matchCompany.map((ele, i) => { return <option value={ele.Id} key={i}>{ele.CompanyName + " (" + ele.Branch + ")"}</option> })}
            </select>
          ) : (
              <input className="company" type="text" placeholder="บริษัท" disabled></input>
            )
          }
          <p className="email-alert">.</p>
          <input className="email" type="text" onChange={emailCallback} placeholder="อีเมล" ></input>
          <p className="password-alert">.</p>
          <input className="password" type="password" onChange={passwordCallback} onKeyPress={(e) => { if (e.keyCode === 13 || e.which === 13) { registerCallback(e) } }} placeholder="รหัสผ่าน"></input>
          <p className="confirm-password-alert">.</p>
          <input className="confirm-password" type="password" onChange={confirmPasswordCallback} onKeyPress={(e) => { if (e.keyCode === 13 || e.which === 13) { registerCallback(e) } }} placeholder="ยืนยันรหัสผ่าน"></input>
          <button className="register-btn noselect" onClick={registerCallback}>สมัครสมาชิก</button>
          <p className="login noselect"><Link to="/login">เข้าสู่ระบบ</Link></p>
        </div>
      </div>
      <div className="loading" style={loading ? { display: "block" } : { display: "none" }}>
        <div className="loading-content">
          <div className="loader"></div>
        </div>
      </div>
      <div className="dialog" style={dialog ? { display: "flex" } : { display: "none" }}>
        <div className="dialog-content">
          <h4>{msg}</h4>
          <Link to="/login">เข้าสู่ระบบ</Link>
        </div>
      </div>
    </div>
  )
}