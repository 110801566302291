// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AuthService from '../../../services/auth.service'
import { useDispatch } from 'react-redux'
import logo from '../../../assets/Moowplay_logo.svg'

export const Login = () => {
  const history = useHistory()
  const [email, setEmail] = useState()
  const [remember, setRemember] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    AuthService.currentUser().then(user => {
      if (user) {
        user.Role === 'admin' ? history.push('/a') : history.push('/u')
      } else {
        const email = window.sessionStorage.getItem('email')
        if (email) {
          document.querySelector('.email').value = email
          setEmail(email)
        }
      }
    })
    dispatch({ type: 'CHANGE_PATH', payload: '/' })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const rememberChecked = localStorage.getItem('rememberChecked') === 'true' ? true : false
    document.querySelector('.remember-me').checked = rememberChecked
    setRemember(rememberChecked)
    // eslint-disable-next-line
  }, [])

  function emailCallback(e) {
    setEmail(e.target.value)
    if (e.target.value.length === 0) {
      alert('.email', '.', false)
    }
  }

  const [password, setPassword] = useState()
  function passwordCallback(e) {
    setPassword(e.target.value)
    if (e.target.value.length === 0) {
      alert('.password', '.', false)
    }
  }

  async function loginCallback(e) {
    e.preventDefault()
    if (!email) {
      promptEmpty('.email')
    } else if (!(/\S+@\S+\.\S+/.test(email))) {
      alert('.email', 'อีเมลไม่ถูกต้อง')
      return
    }
    if (!password) {
      promptEmpty('.password')
    }
    if (email && password) {
      await AuthService.login(email, password, remember ? 'local' : 'session').then((user) => {
        // console.log(user)
        if (user && !user.error) {
          dispatch({ type: 'LOGIN', payload: user.data })
          user.data.role === 'admin' ? history.push('/a') : history.push('/u')
        } else if (user) {
          if (user.error) {
            if (user.data.msg === 'รหัสผ่านไม่ถูกต้อง') {
              alert('.password', user.data.msg)
            } else {
              alert('.email', user.data.msg)
            }
          }
        }
      })
    }
  }

  function promptEmpty(identifier) {
    const ref = document.querySelector(identifier)
    if (!ref.classList.contains('alert')) {
      ref.classList.add('alert')
      //Set alert msg to กรุณากรอกข้อมูล
      document.querySelector(identifier + '-alert').classList.add('show')
      document.querySelector(identifier + '-alert').innerHTML = 'กรุณากรอกข้อมูล'
    }
  }

  function alert(identifier, message = '.', on = true) {
    const boxRef = document.querySelector(identifier)
    const msgRef = document.querySelector(identifier + '-alert')
    if (on) {
      boxRef.classList.add('alert')
      msgRef.classList.add('show')
      msgRef.innerHTML = message
    } else {
      boxRef.classList.remove('alert')
      msgRef.classList.remove('show')
      msgRef.innerHTML = message
    }
  }

  function rememberCallback(e) {
    setRemember(e.target.checked)
    localStorage.setItem('rememberChecked', e.target.checked ? 'true' : 'false')
  }

  return (
    <div className="Login">
      <div className="bgWrapper"></div>
      <div className="container">
        <div className="wrapper">
          <img className="noselect" src={logo} alt="MOOWPLAY" />
          <p className="email-alert">.</p>
          <input autoFocus className="email" type="text" onChange={emailCallback} placeholder="อีเมล" />
          <p className="password-alert">.</p>
          <input className="password" type="password" onChange={passwordCallback} onKeyPress={(e) => { if (e.keyCode === 13 || e.which === 13) { loginCallback(e) } }} placeholder="รหัสผ่าน" />
          <div className="remember noselect">
            <div><input type="checkbox" className="remember-me" onChange={rememberCallback} /></div>
            <label htmlFor="remember-me">จดจำการเข้าสู่ระบบ</label>
            <span className="forget-btn" >
              <Link to="/forget" className="text">ลืมรหัสผ่าน?</Link>
            </span>
          </div>
          <button className="login-btn noselect" onClick={loginCallback}>เข้าสู่ระบบ</button>
          <p className="register noselect" >ยังไม่ได้เป็นสมาชิก? <Link to="/register">สมัครสมาชิก</Link></p>
        </div>
      </div>
    </div>
  )
}