// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth.service'
import DataService from '../../../../services/data.service'
import { Card } from '../Components'
import { useHistory } from 'react-router-dom'
import { MdUpdate, MdSort, MdArrowDropUp, MdArrowDropDown } from 'react-icons/md'
import { FaUsers, FaUserCheck, FaFileAlt, FaFileMedical } from 'react-icons/fa'
import { RiCloseCircleLine, RiAddCircleLine, RiSlideshow2Line, RiContrastDropLine } from 'react-icons/ri'
import './Home.scss'
import { useDispatch } from 'react-redux'
import { detachListener, onLastRecord, onUserSetting, onCompanyStatus } from '../../../../services/socket.service'
import { isMobileOnly } from 'react-device-detect'
import SwipeableViews from 'react-swipeable-views'

export const UserHome = () => {
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [isFunction, setFunction] = useState(undefined)
  const [selectedCName, setCName] = useState('')
  const [selectedCDate, setCDate] = useState('')
  const [cards, setCards] = useState([])
  const [perPage, setPerPage] = useState(window.innerWidth > 767 ? (window.innerWidth > 1365 ? 16 : 9) :(window.innerWidth > 360 ? 6 : 4))
  const [dropUpList, setDropUpList] = useState([])
  const [btnPage, setBtnPage] = useState([])
  const dropUpPreset = window.innerWidth > 1365 || window.innerWidth < 361 ? ['ทั้งหมด', 128, 64, 32, 16, 8, 4] : ['ทั้งหมด', 129, 108, 54, 27, 18, 9, 6, 3]
  const [sort, setSort] = useState(3)
  const [fullscreen, setFullScreen] = useState(false)
  const [slide, setSlide] = useState(false)
  const [interval, setInter] = useState(1000)
  const [slideMenu, setSlideMenu] = useState(false)
  const [dialog, setDialog] = useState(false)
  const [closeDialog, setCloseDialog] = useState(false)
  const dispatch = useDispatch()
  const [user, setUser] = useState()
  const [usersData, setUsersData] = useState([])
  const [totalCall, setTotalCall] = useState(0)
  const [active, setActive] = useState(0)
  const [diff, setDiff] = useState(0)
  const [apiList, setApiList] = useState([])
  const [selected, setSelected] = useState(0)
  const [tab, setTab] = useState([])
  const [show, setShow] = useState()
  const [tabRenderer, setTabRenderer] = useState()
  const [searchInput, setSearchInput] = useState("")
  const [dropupBtn, setDropupBtn] = useState(false)
  const [userSetting, setSetting] = useState({})
  const [cstat, setcstat] = useState([])
  const [report, setReport] = useState(0)
  const [mobileSort, setMobileSort] = useState(false)
  const [usersLastUpdate, setULast] = useState()
  const [selectedId, setsid] = useState()
  const [map, setMap] = useState({
    show: false,
    lat: 0,
    lon: 0,
  })

  var elem = document.documentElement
  var formatter = new Intl.NumberFormat('th-TH', {
    style: 'decimal',
  })

  useEffect(() => {
    AuthService.currentUser().then(user => {
      if (user) {
        setUser(user)
      } else {
        AuthService.logout(history)
      }
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (user) {
      DataService.getCompanyList().then(res => {
        const list = res.filter(ele => {
          if (user.Role === 'admin') {
            //bypass สำหรับ DEV
            return true
          } else {
            if (user.TaxId === ele.TaxId) {
              return true
            } else return false
          }
        })
        setApiList(list)
      })
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (user) {
      onCompanyStatus((payload) => {
        if (payload) {
          setcstat(payload)
        }
      })

      onUserSetting((payload) => {
        if (payload) {
          payload.Setting = JSON.parse(payload.Setting)
          apiList.forEach(ele => {
            detachListener('Company_' + ele.Id)
          })
          setSetting(payload.Setting)
          // console.log(payload)
        }
      }, user.Id, user.CompanyId)
      DataService.getUserSetting(user.CompanyId, user.Id).then(res => {
        if (res.error) {
          if (res.error_middleware) {
            history.push('/session-expired')
          } else {
            console.log(res)
          }
        }
      })
    }

    return () => {
      if (user) {
        detachListener("User_" + user.Id + "_Company_" + user.CompanyId)
      }
      apiList.forEach(ele => {
        detachListener('Company_' + ele.Id)
      })
      detachListener('cstat')
    }
    // eslint-disable-next-line
  }, [apiList])

  useEffect(() => {
    if (user) {
      if (userSetting.added_tab) {
        setTab(userSetting.added_tab)
        if (selectedId && !userSetting.added_tab.some(ele => ele.Id === selectedId)) {
          setSelected(0)
          setsid(tab[0]?.Id)
        }
      }
    }
    // eslint-disable-next-line
  }, [userSetting])

  // useEffect(() => {
  //   renderTab()
  // }, [tab])

  useEffect(() => {
    // console.log(selected)
    const payload = tab[selected]
    if (payload) {
      setReport(payload.Report)

      onLastRecord((payload) => {
        if (payload) {
          // console.log(payload)
          let cData = apiList.filter(elu => elu.Id === payload.CompanyId)
          if (cData.length !== 0) cData = cData[0]
          else cData = null
          if (payload.Data.user) payload.Data.user.forEach(ele => {
            ele.Path = cData.Path
          })
          setShow(payload)
        }
      }, payload.Id)

      DataService.getLastRecord(payload.Id).then(res => {
        if (res.error) {
          if (res.error_middleware) {
            history.push('/session-expired')
          } else {
            console.log(res)
          }
        }
      })
    }

    // renderTab()
    // eslint-disable-next-line
  }, [selected, tab])

  useEffect(() => {
    // console.log(cstat)
    // console.log(tab)
    renderTab()
    // eslint-disable-next-line
  }, [cstat])

  useEffect(() => {
    const payload = show
    if (payload) {
      let cData = apiList.filter(elu => elu.Id === payload.CompanyId)
      if (cData.length !== 0) cData = cData[0]
      else cData = null

      if (!isEmpty(payload.Data)) {
        setActive(payload.Data.active_users)
        setTotalCall(payload.Data.total)
        setUsersData(payload.Data.user)
        setULast(payload.Data.updated_time)
        // setDiff(payload.Data.diff)
        setDiff(payload.Data.day_diff)
        setCDate(formatDate(payload.Data.updated_time))
      } else {
        setActive(null)
        setTotalCall(null)
        setUsersData([])
        setDiff(null)
        setULast(null)
        setCDate('')
      }

      setFunction(payload.ServerStatus === 1 ? true : false)
      setCName(user.Role === 'admin' ? cData?.CompanyName + ' ' + cData?.Branch : cData?.Branch)
    }
    // eslint-disable-next-line
  }, [show])

  useEffect(() => {
    dropUpRender()
    dispatch({ type: 'CHANGE_PATH', payload: '/u' })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (sort === 0) usersData.sort(dynamicsort("UserName", "asc"))
    else if (sort === 1) usersData.sort(dynamicsort("UserName", "desc"))
    else if (sort === 2) {
      usersData.sort(dynamicsort("UserName", "asc"))
      usersData.sort(dynamicsort("Day", "asc"))
    }
    else if (sort === 3) {
      usersData.sort(dynamicsort("UserName", "asc"))
      usersData.sort(dynamicsort("Day", "desc"))
    }
    else if (sort === 4) {
      usersData.sort(dynamicsort("UserName", "asc"))
      // usersData.sort(dynamicsort("update_time", "asc"))
      usersData.sort((a,b)=>new Date(a.LastCall?a.LastCall:a.update_time) - new Date(b.LastCall?b.LastCall:b.update_time))
    }
    else if (sort === 5) {
      usersData.sort(dynamicsort("UserName", "asc"))
      // usersData.sort(dynamicsort("update_time", "desc"))
      usersData.sort((a,b)=>new Date(b.LastCall?b.LastCall:b.update_time) - new Date(a.LastCall?a.LastCall:a.update_time))
  }
    // console.log(usersData)
    pageBtnRender(usersData)
    cardRender(usersData)
    // eslint-disable-next-line
  }, [perPage, page, usersData, sort, searchInput])

  function isEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }


  function selectTabCallback(i) {
    detachListener("Company_" + tab[selected].Id)
    // console.log("Company_" + tab[i].Id)
    setActive(null)
    setTotalCall(null)
    setUsersData([])
    setDiff(null)
    setULast(null)
    setSelected(i)
    setsid(tab[i].Id)
    setPage(1)
    setCDate('')
  }

  function renderTab() {
    const tabs = tab
    // console.log(tabs)
    if (tabs.length === 0) {
      setTabRenderer([])
    } else {
      setTabRenderer(tabs.map((ele, i) => {
        if (i === selected) {
          return null
        }

        let obj = cstat.filter(iter => {
          return iter.CompanyId === ele.Id
        })
        obj = obj.length > 0 ? obj[0] : null

        let cData = apiList.filter(elu => elu.Id === ele.Id)
        if (cData.length !== 0) cData = cData[0]
        else cData = null
        let status = obj?.ServerStatus === 1 ? true : false

        return (
          <div className="tab-container" key={i} onClick={() => selectTabCallback(i)}>
            <div className="head">
              <div className="title" style={{ maxWidth: "100%" }}>
                <h4 style={{ margin: 0, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={user.Role === 'admin' ? cData?.CompanyName + ' ' + cData?.Branch : cData?.Branch}>{user.Role === 'admin' ? cData?.CompanyName + ' ' + cData?.Branch : cData?.Branch}</h4>
                <p style={{ marginTop: "-5px", fontSize: "14px", color: (status !== undefined) ? ((status) ? "#33B240" : "#FF4545") : "#434343" }}>
                  สถานะ : {(status !== undefined) ? ((status) ? "ปกติ" : "เชื่อมต่อไม่ได้") : "ไม่ทราบ"}
                </p>
              </div>
            </div>
          </div>
        )
      }))
    }
  }

  function selectPerPage(selected) {
    setPage(1)
    setPerPage(selected === 'ทั้งหมด' ? -1 : selected)
    document.querySelector('.dropbtn').innerHTML = selected + ' &#xf106;'
    document.querySelector('.dropup-content').style.display = 'none'
  }

  // let rc = 0
  const results = usersData.filter(ele => {
    const name = ele.UserName.toLowerCase()
    const searchInputLower = searchInput.toLowerCase()
    let count = 0
    let loopCount = 0

    for (let index = 0; index < name.length; index++) {
      if (name[index] === searchInputLower[0]) {
        count += 1
      }
    }

    for (let index = 0; index < name.length; index++) {
      const searchLength = searchInputLower.length
      if (name[index] === searchInputLower[0]) {
        loopCount += 1
        let check = true

        for (let index2 = 1; index2 < searchLength; index2++) {
          if (searchInputLower[index2] === name[index + index2]) {
            //
          } else {
            check = false
            break
          }
        }

        if (loopCount >= count) {
          if (check) {
            // rc += 1
          }
          return check
        } else {
          if (check) {
            // rc += 1
            return check
          }
        }
      }
    }
  });

  function cardRender(list) {
    if (searchInput !== "") {
      setPerPage(-1)
      selectPerPage('ทั้งหมด')
      results.sort((a, b) => {
        if (searchInput.length === 1) {
          if (a.UserName[0].toLowerCase() === searchInput[0].toLowerCase() && b.UserName[0].toLowerCase() !== searchInput[0].toLowerCase()) {
            return -1
          } else if (a.UserName[0].toLowerCase() !== searchInput[0].toLowerCase() && b.UserName[0].toLowerCase() === searchInput[0].toLowerCase()) {
            return 1
          } else if (a.UserName[0].toLowerCase() === searchInput[0].toLowerCase() && b.UserName[0].toLowerCase() === searchInput[0].toLowerCase()) {
            if (a.UserName.toLowerCase().slice(1) > b.UserName.toLowerCase().slice(1)) {
              return 1
            } else {
              return -1
            }
          }
          else {
            if (a.UserName.toLowerCase() > b.UserName.toLowerCase()) {
              return 1
            } else if (a.UserName.toLowerCase() < b.UserName.toLowerCase()) {
              return -1
            } else {
              return -1
            }
          }
        } else {
          if (a.UserName.toLowerCase() > b.UserName.toLowerCase()) {
            return 1
          } else if (a.UserName.toLowerCase() < b.UserName.toLowerCase()) {
            return -1
          } else {
            return -1
          }
        }
      })
      return setCards(results.map((ele, i) => {
        ele.status = isFunction
        ele.update_time = usersLastUpdate
        return <Card obj={ele} key={i} /> 
      }))
    }
    else {
      setCards(list.slice((page - 1) * perPage, perPage !== -1 ? page * perPage : list.length).map((ele, i) => {
        ele.status = isFunction
        ele.update_time = usersLastUpdate
        return <Card obj={ele} key={i} onClick={()=>locationCallback(ele)}/>
      }))
    }
  }

  function locationCallback(obj){
    const lat = obj.Latitude
    const lon = obj.Longitude
    // console.log(lat+' '+lon)
    // console.log(lat != 0 || lon!= 0)
    if(lat !== 0 || lon !== 0)
    setMap({
      show: true,
      lat: lat,
      lon: lon,
    })
    else
    setMap({
      show: true,
      lat: 'N/A',
      lon: 'N/A',
    })
    // console.log(map)
  }

  function formatDate(timestr) {
    const date = new Date(timestr)
    const dd = parseInt(date.getDate()) > 9 ? parseInt(date.getDate()) : '0' + parseInt(date.getDate())
    const mm = (parseInt(date.getMonth()) + 1) > 9 ? (parseInt(date.getMonth()) + 1) : '0' + (parseInt(date.getMonth()) + 1)
    const yyyy = ('' + date.getFullYear()).substring(2, 4)
    const hh = parseInt(date.getHours()) > 9 ? parseInt(date.getHours()) : '0' + parseInt(date.getHours())
    const min = parseInt(date.getMinutes()) > 9 ? parseInt(date.getMinutes()) : '0' + parseInt(date.getMinutes())
    return dd + '/' + mm + '/' + yyyy + ' ' + hh + ':' + min + ' น.'
  }

  function pageBtnRender(list) {
    let temp = []
    const pages = Math.ceil(list.length / (perPage !== -1 ? perPage : list.length))
    setLastPage(pages ? pages : 0)
    const maxPages = window.innerWidth > 400 ? 5 : 4
    let start = 1
    let end = pages > maxPages ? maxPages : pages
    if (pages > maxPages && page !== 1) {
      start = page - 1
      end = start + maxPages - 1
      if (end > pages) {
        start = start - (end - pages)
        end = pages
      }
    }
    for (let i = start; i <= end; i++) {
      temp.push(<button className={(page === i) ? "selected" : null} onClick={() => setPage(i)} key={i}>{i}</button>)
    }
    setBtnPage(temp)
  }

  function dropUpRender() {
    setDropUpList(dropUpPreset.map((ele, i) => {
      return <button onMouseDown={() => { selectPerPage(ele); setDropupBtn(false) }} key={i}>{ele}</button>
    }))
  }

  function sortToggle(sel) {
    if (sel === 0) { // เลือกชื่อ
      if (sort === 0) setSort(1) // ▲ ⇒ ▼
      else if (sort === 1) setSort(0) // ▼ ⇒ ▲
      else setSort(0) // มาจากอันอื่น
    }
    if (sel === 1) { // เลือกจำนวนการโทร
      if (sort === 2) setSort(3) // ▲ ⇒ ▼
      else if (sort === 3) setSort(2) // ▼ ⇒ ▲
      else setSort(2) // มาจากอันอื่น
    }
    if (sel === 2) { // เลือกเวลาโทรล่าสุด
      if (sort === 4) setSort(5) // ▲ ⇒ ▼
      else if (sort === 5) setSort(4) // ▼ ⇒ ▲
      else setSort(4) // มาจากอันอื่น
    }
  }

  function dynamicsort(property, order) {
    var sort_order = 1;
    if (order === "desc") {
      sort_order = -1;
    }
    return function (a, b) {
      // a should come before b in the sorted order
      if (typeof (a[property]) === "string" || typeof (a[property]) === "string") {
        if (a[property].toLowerCase() < b[property].toLowerCase()) {
          return -1 * sort_order;
          // a should come after b in the sorted order
        } else if (a[property].toLowerCase() > b[property].toLowerCase()) {
          return 1 * sort_order;
          // a and b are the same
        } else {
          return 0 * sort_order;
        }
      }
      else {
        if (a[property] < b[property]) {
          return -1 * sort_order;
          // a should come after b in the sorted order
        } else if (a[property] > b[property]) {
          return 1 * sort_order;
          // a and b are the same
        } else {
          return 0 * sort_order;
        }
      }
    }
  }

  function toggleFullScreen() {
    if (!fullscreen) {
      // setFullScreen(true)
      openFullscreen()
      dispatch({ type: "ENABLE_FULLSCREEN" })
    }
    else {
      // setFullScreen(false)
      if (document.fullscreenElement !== null) closeFullscreen()
      dispatch({ type: "DISABLE_FULLSCREEN" })
    }
  }

  document.addEventListener('fullscreenchange', (event) => {
    if (document.fullscreenElement) {
      setFullScreen(true)
      dispatch({ type: "ENABLE_FULLSCREEN" })
    } else {
      setFullScreen(false)
      dispatch({ type: "DISABLE_FULLSCREEN" })
    }
  })

  useEffect(() => {
    if (document.getElementById("slideshow-interval")) setInter(document.getElementById("slideshow-interval").value * 1000)
    if (slide) {
      const i = setInterval(() => {
        slideShow()
      }, interval);
      return () => clearInterval(i);
    }
    // eslint-disable-next-line
  }, [slide, interval, page]);

  function slideShow() {
    if (page < lastPage) {
      setPage(page + 1);
    }
    else {
      setPage(1)
    }
  }

  function addDbCallback(e) {
    let selected = document.getElementById('company').value
    if (selected === '') return
    selected = parseInt(selected)

    // console.log('adding ' + selected)
    let settings = {}
    if (isEmpty(userSetting)) {
      settings = {
        added_tab: [{ Id: selected, Report: 1 }]
      }
    } else {
      settings = {
        added_tab: userSetting.added_tab.concat([{ Id: selected, Report: 1 }])
      }
    }

    DataService.updateUserSetting(user.CompanyId, user.Id, settings).then(res => {
      if (res.error) {
        if (res.error_middleware) {
          history.push('/session-expired')
        } else {
          console.log(res)
        }
      }
    })

    document.getElementById('company').value = ''
    setDialog(false)
  }

  function deleteTabCallback() {
    // console.log('Attempt to delete '+tab[selected]?.Id)
    // console.log(userSetting)
    const index = userSetting.added_tab.map(ele => ele.Id).indexOf(tab[selected]?.Id)
    if (index > -1) {
      userSetting.added_tab.splice(index, 1)
    }
    setPage(1)
    setCards([])
    setCName('')
    setCDate('')
    setUsersData([])
    setTotalCall(0)
    setActive(0)
    setDiff(0)
    setFunction(undefined)

    DataService.updateUserSetting(user.CompanyId, user.Id, userSetting).then(res => {
      if (res.error) {
        if (res.error_middleware) {
          history.push('/session-expired')
        } else {
          console.log(res)
        }
      }
    })
    setSelected(0)
    setsid(tab[0]?.Id)
    setCloseDialog(false)
  }

  function toggleReportCallback() {
    let setting = userSetting
    setting.added_tab = setting.added_tab.map(ele => {
      if (ele.Id === tab[selected].Id) {
        ele.Report = ele.Report === 1 ? 0 : 1
      }
      return ele
    })
    DataService.updateUserSetting(user.CompanyId, user.Id, setting).then(res => {
      if (res.error) {
        if (res.error_middleware) {
          history.push('/session-expired')
        } else {
          console.log(res)
        }
      }
    })
  }

  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }

  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  function downloadCallback() {
    // console.log(tab[selected].Id)
    DataService.download(tab[selected].Id)
  }

  function checkNull(list) {
    for (let i = 0; i < list.length; i++) {
      if (list[i] !== null) return false
    }
    return true
  }

  return (
    <>
      {!isMobileOnly ? (window.innerWidth > 923 ? ( //PC
        <div className="Home">
          <div className="container">
            {(fullscreen) ? null : (<div className="left-bar">
              <div className="name">
                <h3 style={{ color: "#434343", margin: 0 }} title={user?.CompanyName} >{user?.CompanyName ? user.CompanyName : "ไม่พบข้อมูล"}</h3>
                <p style={{ color: "#525252", margin: 0, fontSize: "14px" }}>Tax ID : {user?.TaxId ? user.TaxId : "ไม่พบข้อมูล"}</p>
              </div>
              <div className="data-tab">
                <div className="current-tab">
                  <div className="head">
                    <div className="title noselect">
                      <h3 style={{ margin: 0, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={selectedCName}>{selectedCName}</h3>
                      <p style={{ marginTop: "-5px", fontSize: "14px", color: ((isFunction !== undefined) ? ((isFunction) ? "#33B240" : "#FF4545") : "#d69600") }}>
                        สถานะ : {(isFunction !== undefined) ? ((isFunction) ? "ปกติ" : "เชื่อมต่อไม่ได้") : "ไม่ทราบ"}
                      </p>
                    </div>
                    {tab[selected]?.Id !== user?.CompanyId && <button title="ลบฐานข้อมูลนี้" onClick={() => setCloseDialog(true)}><RiCloseCircleLine /></button>}
                  </div>
                  <div className="info">
                    <div className="user" title="จำนวนผู้ใช้ทั้งหมด">
                      <FaUsers className="icon" />
                      <h5 style={{ display: "inline", fontSize: "18px" }}>{formatter.format(usersData.length)}</h5>
                    </div>
                    <div className="active-user" title="จำนวนผู้ใช้แอคทีฟล่าสุด">
                      <FaUserCheck className="icon" />
                      <h5 style={{ display: "inline", fontSize: "18px" }}>{formatter.format(active)}</h5>
                    </div>
                    <div className="files" title="จำนวนไฟล์ทั้งหมด">
                      <FaFileAlt className="icon" />
                      <h5 style={{ display: "inline", fontSize: "18px" }}>{formatter.format(totalCall)}</h5>
                    </div>
                    <div className="new-files" title="จำนวนไฟล์ที่เพิ่มล่าสุด">
                      <FaFileMedical className="icon" />
                      <h5 style={{ display: "inline", fontSize: "18px" }}>{formatter.format(diff)}</h5>
                    </div>
                  </div>
                  <div className="report noselect">
                    <div className="btn-group" >
                      <h5 style={{ display: "inline" }}>รายงานผล</h5>
                      {/* <button className="send-btn">ส่งทางอีเมล</button> */}
                      <button className="download-btn" onClick={downloadCallback}>ดาวน์โหลด</button>
                    </div>
                    <div className="btn-group" style={{ marginTop: "10px" }} >
                      <h5 style={{ display: "inline" }}>ส่งรายงานประจำวันอัตโนมัติ&nbsp;</h5>
                      <label className="switch">
                        <input type="checkbox" checked={report} onChange={toggleReportCallback} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <p title="อัปเดตล่าสุด" className="noselect" style={{ margin: 0, fontSize: "14px", display: "inline-flex", alignItems: "center", alignSelf: "flex-end" }}>
                    <MdUpdate style={{ fontSize: "16px", marginRight: "5px" }} />{selectedCDate ? selectedCDate : "ไม่พบข้อมูล"}
                  </p>
                </div>
                <div className="tabs noselect">
                  {tabRenderer}
                </div>
              </div>
              <button className="new-btn noselect" onClick={() => setDialog(true)}>
                <RiAddCircleLine />&nbsp;<h5 style={{ display: "inline", fontSize: "18px" }}>เพิ่มฐานข้อมูล</h5>
              </button>
            </div>)}
            <div className={(fullscreen) ? "wrapper fullscreen" : "wrapper"}>
              <div className="inner-bar">
                <input type="seach" placeholder="&#xF002; ค้นหา..." onInput={e => setSearchInput(e.target.value)} />
                <div className="btn-group noselect" >
                  <p style={{ display: "inline", color: "white" }}>เรียงตาม : </p>
                  <button className={(sort === 0 || sort === 1) ? "selected" : ""} onClick={() => sortToggle(0)}>ชื่อ{(sort === 0) ? <MdArrowDropUp /> : ((sort === 1) ? <MdArrowDropDown /> : "")}</button>
                  <button className={(sort === 2 || sort === 3) ? "selected" : ""} onClick={() => sortToggle(1)}>จำนวนการโทร{(sort === 2) ? <MdArrowDropUp /> : ((sort === 3) ? <MdArrowDropDown /> : "")}</button>
                  <button className={(sort === 4 || sort === 5) ? "selected" : ""} onClick={() => sortToggle(2)}>เวลาโทรล่าสุด{(sort === 4) ? <MdArrowDropUp /> : ((sort === 5) ? <MdArrowDropDown /> : "")}</button>
                </div>
                <div className="mobile-sort">
                  <button onClick={() => { mobileSort ? setMobileSort(false) : setMobileSort(true) }}><MdSort style={{ fontSize: "30px", color: "white" }} /></button>
                  <div className="mobile-sort-menu" style={mobileSort ? { display: "inline-flex" } : { display: "none" }} onMouseLeave={() => setMobileSort(false)}>
                    <div className="mobile-sort-btn" >
                      <button className={(sort === 0 || sort === 1) ? "selected" : ""} onClick={() => { sortToggle(0) }}>ชื่อ{(sort === 0) ? <MdArrowDropUp /> : ((sort === 1) ? <MdArrowDropDown /> : "")}</button>
                      <button className={(sort === 2 || sort === 3) ? "selected" : ""} onClick={() => { sortToggle(1) }}>จำนวนการโทร{(sort === 2) ? <MdArrowDropUp /> : ((sort === 3) ? <MdArrowDropDown /> : "")}</button>
                      <button className={(sort === 4 || sort === 5) ? "selected" : ""} onClick={() => { sortToggle(2) }}>เวลาโทรล่าสุด{(sort === 4) ? <MdArrowDropUp /> : ((sort === 5) ? <MdArrowDropDown /> : "")}</button>
                      <p style={{ width: "fit-content", alignSelf: "center" }}>แสดงทีละ :</p>
                      <select className="perpage-select-mobile" defaultValue={window.innerWidth > 1365 ? 16 : 6} onChange={e => { setPerPage(parseInt(e.target.value)); setMobileSort(false) }}>
                        {dropUpPreset.reverse().map((ele, i) => { return <option value={ele === 'ทั้งหมด' ? -1 : ele} key={i} >{ele}</option> })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-wrapper" style={(fullscreen) ? { maxHeight: `calc(${"var(--vh, 1vh) * 100 - 125px"})` } : {}}>
                {!checkNull(cards) ? cards
                  : <div className="noselect" style={{ display: "flex", width: "100%", height: "calc(100vh - 195px)", justifyContent: "center" }}>
                    <h3 style={{
                      padding: "20px 50px", width: "fit-content", borderRadius: "10px", textAlign: "center", backgroundColor: "#ffffff80",
                      display: "flex", alignItems: "center", justifyContent: "center", alignSelf: "center", boxSizing: "border-box"
                    }}>
                      ไม่พบข้อมูล</h3>
                  </div>}
              </div>
              <div className="inner-bar2">
                <div className="btn-group noselect" >
                  <p style={{ display: "inline", color: "white" }}>แสดงทีละ : </p>
                  <div className="dropup">
                    <button className="dropbtn" style={dropupBtn ? { backgroundColor: "#BCEAF9", borderRadius: "0 0 5px 5px" } : null} onClick={() => { (dropupBtn) ? setDropupBtn(false) : setDropupBtn(true) }} onBlur={() => setDropupBtn(false)}>{window.innerWidth > 1365 ? 16 : 6} &#xf106;</button>
                    <div className="dropup-content" style={dropupBtn ? { display: "block", borderRadius: "5px 5px 0 0" } : { display: "none" }}>
                      {dropUpList}
                    </div>
                  </div>
                  <div className="present">
                    <div className="present-menu" style={(slideMenu) ? { display: "block" } : { display: "none" }}>
                      <button className="close-pmenu" onClick={() => setSlideMenu(false)}>×</button>
                      <div className="btn-group">
                        <h4>โหมดเต็มหน้าจอ&nbsp;</h4>
                        <label className="switch">
                          <input type="checkbox" id="fullscreen-checkbox" checked={fullscreen} onChange={toggleFullScreen} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="btn-group">
                        <h4>สไลด์โชว์&nbsp;</h4>
                        <label className="switch">
                          <input type="checkbox" id="slideshow-checkbox" onChange={e => { (e.target.checked) ? setSlide(true) : setSlide(false) }} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <label>
                        <br />เลื่อนทุก
                    <input type="number" id="slideshow-interval" defaultValue={3} min={1} style={{ maxWidth: "80px" }} onInput={e => setInter(e.target.value * 1000)} />
                        <h5 style={{ display: "inline" }}>วินาที</h5>
                      </label>
                    </div>
                    <button className="present-btn" title="โหมดนำเสนอ" onClick={() => { slideMenu ? setSlideMenu(false) : setSlideMenu(true) }} style={(slideMenu) ? { backgroundColor: "#ffffffcc" } : {}} ><RiSlideshow2Line style={{ fontSize: "22px", marginTop: "4px" }} /></button>
                  </div>
                </div>
                <div className="pagination noselect" style={{ display: "inline" }}>
                  {(lastPage > 2) ? <button disabled={(page === 1) ? true : false} onClick={() => setPage(1)}>&#xf100;</button> : null}
                  <button disabled={(page === 1 || lastPage <= 0) ? true : false} onClick={() => setPage(page - 1)}>&#xf104;</button>
                  {btnPage}
                  <button disabled={(page === lastPage || lastPage <= 0) ? true : false} onClick={() => setPage(page + 1)}>&#xf105;</button>
                  {(lastPage > 2) ? <button disabled={(page === lastPage) ? true : false} onClick={() => setPage(lastPage)}>&#xf101;</button> : null}
                </div>
              </div>
            </div>
          </div>
          <div className="map noselect" style={map.show? { display: "block"}: { display: "none"}}>
            <div className="map-content">
              <button className="close" onClick={()=>setMap({show:false,lat:0,lon:0})}>×</button>
              {(map.lat!=='N/A') && <iframe
                title="แผนที่แสดงพิกัดการโทรล่าสุด"
                className="map-api"
                frameBorder="0"
                src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyBAfPQMDDIHwTgT8Vd3-mm0aUy9soDJbVI&q="+map.lat+","+map.lon} allowFullScreen>
              </iframe>}
              {(map.lat==='N/A') && <div className="map-api2">ไม่สามารถระบุตำแหน่ง</div>}
            </div>
          </div>
          <div className="dialog noselect" style={dialog ? { display: "block" } : { display: "none" }}>
            <div className="dialog-content">
              <button className="close" onClick={() => setDialog(false)}>×</button>
              <h4>เลือกฐานข้อมูล</h4>
              <select id="company" className="company" defaultValue="">
                <option value="" disabled >กรุณาเลือกฐานข้อมูล...</option>
                {apiList.map((ele, i) => {
                  if (tab.some(iter => iter.Id === ele.Id) || ele.CompanyName === 'ADMIN') {
                    return null
                  }
                  if (user?.Role === 'admin') {
                    return <option value={ele.Id} key={i}>{ele.CompanyName + ' ' + ele.Branch}</option>
                  } else {
                    return <option value={ele.Id} key={i}>{ele.Branch}</option>
                  }
                })}
              </select>
              <button className="add-db" onClick={addDbCallback}>เพิ่ม</button>
            </div>
          </div>
          <div className="del-dialog noselect" style={closeDialog ? { display: "block" } : { display: "none" }}>
            <div className="dialog-content">
              <h4>คุณต้องการลบรายการนี้ใช่หรือไม่</h4>
              <button className="btn cancel" onClick={() => setCloseDialog(false)}>ยกเลิก</button>
              <button className="btn" onClick={deleteTabCallback}>ลบ</button>
            </div>
          </div>
        </div>) : ( //Tablet
          <div className="Home">
            <div className="container">
              <div className="left-bar">
                <div className="name">
                  <h3 style={{ color: "#434343", margin: 0 }}>{user?.CompanyName ? user.CompanyName : "ไม่พบข้อมูล"}</h3>
                  <p style={{ color: "#525252", margin: 0, fontSize: "14px" }}>Tax ID : {user?.TaxId ? user.TaxId : "ไม่พบข้อมูล"}</p>
                </div>
                <div className="data-tab">
                  <div className="current-tab">
                    <div className="head">
                      <div className="title noselect">
                        <h3 style={{ margin: 0, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={selectedCName}>{selectedCName}</h3>
                        <p style={{ marginTop: "-5px", fontSize: "14px", color: ((isFunction !== undefined) ? ((isFunction) ? "#33B240" : "#FF4545") : "#d69600") }}>
                          สถานะ : {(isFunction !== undefined) ? ((isFunction) ? "ปกติ" : "เชื่อมต่อไม่ได้") : "ไม่ทราบ"}
                        </p>
                      </div>
                      {tab[selected]?.Id !== user?.CompanyId && <button title="ลบฐานข้อมูลนี้" onClick={() => setCloseDialog(true)}><RiCloseCircleLine /></button>}
                    </div>
                    <div className="info">
                      <div className="user" title="ผู้ใช้ทั้งหมด">
                        <FaUsers className="icon" />
                        <h5 style={{ display: "inline", fontSize: "18px" }}>{formatter.format(usersData.length)}</h5>
                      </div>
                      <div className="active-user" title="ผู้ใช้แอคทีฟ">
                        <FaUserCheck className="icon" />
                        <h5 style={{ display: "inline", fontSize: "18px" }}>{formatter.format(active)}</h5>
                      </div>
                      <div className="files" title="ไฟล์ทั้งหมด">
                        <FaFileAlt className="icon" />
                        <h5 style={{ display: "inline", fontSize: "18px" }}>{formatter.format(totalCall)}</h5>
                      </div>
                      <div className="new-files" title="ไฟล์ใหม่">
                        <FaFileMedical className="icon" />
                        <h5 style={{ display: "inline", fontSize: "18px" }}>{formatter.format(diff)}</h5>
                      </div>
                    </div>
                    <div className="report noselect">
                      <div className="btn-group" >
                        <h5 style={{ display: "inline" }}>รายงานผล</h5>
                        {/* <button className="send-btn">ส่งทางอีเมล</button> */}
                        <button className="download-btn" onClick={downloadCallback}>ดาวน์โหลด</button>
                      </div>
                      <div className="btn-group" style={{ marginTop: "10px" }} >
                        <h5 style={{ display: "inline" }}>ส่งรายงานประจำวันอัตโนมัติ&nbsp;</h5>
                        <label className="switch">
                          <input type="checkbox" checked={report} onChange={toggleReportCallback} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p title="อัปเดตล่าสุด" className="noselect" style={{ margin: 0, fontSize: "14px", display: "inline-flex", alignItems: "center", alignSelf: "flex-end" }}>
                      <MdUpdate style={{ fontSize: "16px", marginRight: "5px" }} />{selectedCDate ? selectedCDate : "ไม่พบข้อมูล"}
                    </p>
                  </div>
                  <div className="tabs noselect">
                    {tabRenderer}
                  </div>
                </div>
                <button className="new-btn noselect" onClick={() => setDialog(true)}>
                  <RiAddCircleLine />&nbsp;<h5 style={{ display: "inline", fontSize: "18px" }}>เพิ่มฐานข้อมูล</h5>
                </button>
              </div>
              <div className="wrapper">
                <div className="inner-bar">
                  <input type="seach" placeholder="&#xF002; ค้นหา..." onInput={e => setSearchInput(e.target.value)} />
                  <div className="btn-group noselect" >
                    <p style={{ display: "inline", color: "white" }}>เรียงตาม : </p>
                    <button className={(sort === 0 || sort === 1) ? "selected" : ""} onClick={() => sortToggle(0)}>ชื่อ{(sort === 0) ? <MdArrowDropUp /> : ((sort === 1) ? <MdArrowDropDown /> : "")}</button>
                    <button className={(sort === 2 || sort === 3) ? "selected" : ""} onClick={() => sortToggle(1)}>จำนวนการโทร{(sort === 2) ? <MdArrowDropUp /> : ((sort === 3) ? <MdArrowDropDown /> : "")}</button>
                    <button className={(sort === 4 || sort === 5) ? "selected" : ""} onClick={() => sortToggle(2)}>เวลาโทรล่าสุด{(sort === 4) ? <MdArrowDropUp /> : ((sort === 5) ? <MdArrowDropDown /> : "")}</button>
                  </div>
                  <div className="mobile-sort noselect">
                    <button onClick={() => { mobileSort ? setMobileSort(false) : setMobileSort(true) }}><MdSort style={{ fontSize: "30px", color: "white" }} /></button>
                    <div className="mobile-sort-menu" style={mobileSort ? { display: "inline-flex" } : { display: "none" }} onMouseLeave={() => setMobileSort(false)}>
                      <div className="mobile-sort-btn" >
                        <button className={(sort === 0 || sort === 1) ? "selected" : ""} onClick={() => { sortToggle(0) }}>ชื่อ{(sort === 0) ? <MdArrowDropUp /> : ((sort === 1) ? <MdArrowDropDown /> : "")}</button>
                        <button className={(sort === 2 || sort === 3) ? "selected" : ""} onClick={() => { sortToggle(1) }}>จำนวนการโทร{(sort === 2) ? <MdArrowDropUp /> : ((sort === 3) ? <MdArrowDropDown /> : "")}</button>
                        <button className={(sort === 4 || sort === 5) ? "selected" : ""} onClick={() => { sortToggle(2) }}>เวลาโทรล่าสุด{(sort === 4) ? <MdArrowDropUp /> : ((sort === 5) ? <MdArrowDropDown /> : "")}</button>
                        <p style={{ width: "fit-content", alignSelf: "center" }}>แสดงทีละ :</p>
                        <select className="perpage-select-mobile" defaultValue={window.innerWidth > 1365 ? 16 : 6} onChange={e => { setPerPage(parseInt(e.target.value)); setMobileSort(false) }}>
                          {dropUpPreset.reverse().map((ele, i) => { return <option value={ele === 'ทั้งหมด' ? -1 : ele} key={i} >{ele}</option> })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-wrapper" style={(fullscreen) ? { maxHeight: `calc(${"var(--vh, 1vh) * 100 - 125px"})` } : {}}>
                  {!checkNull(cards) ? cards
                    : <div className="noselect" style={{ display: "flex", width: "100%", height: "calc(100vh - 195px)", justifyContent: "center" }}>
                      <h3 style={{
                        padding: "20px 50px", width: "fit-content", borderRadius: "10px", textAlign: "center", backgroundColor: "#ffffff80",
                        display: "flex", alignItems: "center", justifyContent: "center", alignSelf: "center", boxSizing: "border-box"
                      }}>
                        ไม่พบข้อมูล</h3>
                    </div>}
                </div>
                <div className="inner-bar2">
                  <div className="btn-group noselect" >
                    <p style={{ display: "inline", color: "white" }}>แสดงทีละ : </p>
                    <div className="dropup">
                      <button className="dropbtn" style={dropupBtn ? { backgroundColor: "#BCEAF9", borderRadius: "0 0 5px 5px" } : null} onClick={() => { (dropupBtn) ? setDropupBtn(false) : setDropupBtn(true) }} onBlur={() => setDropupBtn(false)}>15 &#xf106;</button>
                      <div className="dropup-content" style={dropupBtn ? { display: "block", borderRadius: "5px 5px 0 0" } : { display: "none" }}>
                        {dropUpList}
                      </div>
                    </div>
                    <div className="present">
                      <div className="present-menu" style={(slideMenu) ? { display: "block" } : { display: "none" }}>
                        <button className="close-pmenu" onClick={() => setSlideMenu(false)}>×</button>
                        <div className="btn-group">
                          <h4>โหมดเต็มหน้าจอ&nbsp;</h4>
                          <label className="switch">
                            <input type="checkbox" id="fullscreen-checkbox" checked={fullscreen} onChange={toggleFullScreen} />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="btn-group">
                          <h4>สไลด์โชว์&nbsp;</h4>
                          <label className="switch">
                            <input type="checkbox" id="slideshow-checkbox" onChange={e => { (e.target.checked) ? setSlide(true) : setSlide(false) }} />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <label>
                          <br />เลื่อนทุก
                  <input type="number" id="slideshow-interval" defaultValue={3} min={1} style={{ maxWidth: "80px" }} onInput={e => setInter(e.target.value * 1000)} />
                          <h5 style={{ display: "inline" }}>วินาที</h5>
                        </label>
                      </div>
                      <button className="present-btn" title="โหมดนำเสนอ" onClick={() => { slideMenu ? setSlideMenu(false) : setSlideMenu(true) }} style={(slideMenu) ? { backgroundColor: "#ffffffcc" } : {}} ><RiSlideshow2Line style={{ fontSize: "22px", marginTop: "4px" }} /></button>
                    </div>
                  </div>
                  <div className="pagination noselect" style={{ display: "inline" }}>
                    {(lastPage > 2) ? <button disabled={(page === 1) ? true : false} onClick={() => setPage(1)}>&#xf100;</button> : null}
                    <button disabled={(page === 1 || lastPage <= 0) ? true : false} onClick={() => setPage(page - 1)}>&#xf104;</button>
                    {btnPage}
                    <button disabled={(page === lastPage || lastPage <= 0) ? true : false} onClick={() => setPage(page + 1)}>&#xf105;</button>
                    {(lastPage > 2) ? <button disabled={(page === lastPage) ? true : false} onClick={() => setPage(lastPage)}>&#xf101;</button> : null}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="map noselect" style={map.show? { display: "block"}: { display: "none"}}>
                <div className="map-content">
                  <button className="close" onClick={()=>setMap({show:false,lat:0,lon:0})}>×</button>
                  {(map.lat!=='N/A') && <iframe
                    title="แผนที่แสดงพิกัดการโทรล่าสุด"
                    className="map-api"
                    frameBorder="0"
                    src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyBAfPQMDDIHwTgT8Vd3-mm0aUy9soDJbVI&q="+map.lat+","+map.lon} allowFullScreen>
                  </iframe>}
                  {(map.lat==='N/A') && <div className="map-api2">ไม่สามารถระบุตำแหน่ง</div>}
                </div>
              </div>
              <div className="dialog noselect" style={dialog ? { display: "block" } : { display: "none" }}>
                <div className="dialog-content">
                  <div className="close" onClick={() => setDialog(false)}>×</div>
                  <h4>เลือกฐานข้อมูล</h4>
                  <div>
                    <select id="company" className="company" defaultValue="">
                      <option value="" disabled >กรุณาเลือกฐานข้อมูล...</option>
                      {apiList.map((ele, i) => {
                        if (tab.some(iter => iter.Id === ele.Id) || ele.CompanyName === 'ADMIN') {
                          return null
                        }
                        if (user?.Role === 'admin') {
                          return <option value={ele.Id} key={i}>{ele.CompanyName + ' ' + ele.Branch}</option>
                        } else {
                          return <option value={ele.Id} key={i}>{ele.Branch}</option>
                        }
                      })}
                    </select>
                    <button className="add-db" onClick={addDbCallback}>เพิ่ม</button>
                  </div>
                </div>
              </div>
              <div className="del-dialog noselect" style={closeDialog ? { display: "block" } : { display: "none" }}>
                <div className="dialog-content">
                  <h4>คุณต้องการลบรายการนี้ใช่หรือไม่</h4>
                  <button className="btn cancel" onClick={() => setCloseDialog(false)}>ยกเลิก</button>
                  <button className="btn" onClick={deleteTabCallback}>ลบ</button>
                </div>
              </div>
            </div>
          </div>
        )) : ( //Mobile
          <SwipeableViews className="Home" enableMouseEvents>
            <div className="container">
              <div className="left-bar">
                <div className="name">
                  <h3 style={{ color: "#434343", margin: 0 }}>{user?.CompanyName ? user.CompanyName : "ไม่พบข้อมูล"}</h3>
                  <p style={{ color: "#525252", margin: 0, fontSize: "14px" }}>Tax ID : {user?.TaxId ? user.TaxId : "ไม่พบข้อมูล"}</p>
                </div>
                <div className="data-tab">
                  <div className="current-tab">
                    <div className="head">
                      <div className="title noselect">
                        <h3 style={{ margin: 0, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={selectedCName}>{selectedCName}</h3>
                        <p style={{ marginTop: "-5px", fontSize: (window.innerWidth > 374 ? '14px' : '10px'), color: ((isFunction !== undefined) ? ((isFunction) ? "#33B240" : "#FF4545") : "#d69600") }}>
                          สถานะ : {(isFunction !== undefined) ? ((isFunction) ? "ปกติ" : "เชื่อมต่อไม่ได้") : "ไม่ทราบ"}
                        </p>
                      </div>
                      {tab[selected]?.Id !== user?.CompanyId && <button title="ลบฐานข้อมูลนี้" onClick={() => setCloseDialog(true)}><RiCloseCircleLine /></button>}
                    </div>
                    <div className="info">
                      <div className="user" title="ผู้ใช้ทั้งหมด">
                        <FaUsers className="icon" />
                        <h5 style={{ display: "inline", fontSize: (window.innerWidth > 374 ? '18px' : '14px') }}>{formatter.format(usersData.length)}</h5>
                      </div>
                      <div className="active-user" title="ผู้ใช้แอคทีฟ">
                        <FaUserCheck className="icon" />
                        <h5 style={{ display: "inline", fontSize: (window.innerWidth > 374 ? '18px' : '14px') }}>{formatter.format(active)}</h5>
                      </div>
                      <div className="files" title="ไฟล์ทั้งหมด">
                        <FaFileAlt className="icon" />
                        <h5 style={{ display: "inline", fontSize: (window.innerWidth > 374 ? '18px' : '14px') }}>{formatter.format(totalCall)}</h5>
                      </div>
                      <div className="new-files" title="ไฟล์ใหม่">
                        <FaFileMedical className="icon" />
                        <h5 style={{ display: "inline", fontSize: (window.innerWidth > 374 ? '18px' : '14px') }}>{formatter.format(diff)}</h5>
                      </div>
                    </div>
                    <div className="report noselect">
                      <div className="btn-group" >
                        <h5 style={{ display: "inline" }}>รายงานผล</h5>
                        {/* <button className="send-btn">ส่งทางอีเมล</button> */}
                        <button className="download-btn" onClick={downloadCallback}>ดาวน์โหลด</button>
                      </div>
                      <div className="btn-group" style={{ marginTop: "10px" }} >
                        <h5 style={{ display: "inline" }}>ส่งรายงานประจำวันอัตโนมัติ&nbsp;</h5>
                        <label className="switch">
                          <input type="checkbox" checked={report} onChange={toggleReportCallback} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p title="อัปเดตล่าสุด" className="noselect" style={{ margin: 0, fontSize: "14px", display: "inline-flex", alignItems: "center", alignSelf: "flex-end" }}>
                      <MdUpdate style={{ fontSize: "16px", marginRight: "5px" }} />{selectedCDate ? selectedCDate : "ไม่พบข้อมูล"}
                    </p>
                  </div>
                  <div className="tabs noselect">
                    {tabRenderer}
                  </div>
                </div>
                <button className="new-btn noselect" onClick={() => setDialog(true)}>
                  <RiAddCircleLine />&nbsp;<h5 style={{ display: "inline", fontSize: "18px" }}>เพิ่มฐานข้อมูล</h5>
                </button>
              </div>
              <div className="wrapper">
                <div className="inner-bar">
                  <input type="seach" placeholder="&#xF002; ค้นหา..." onInput={e => setSearchInput(e.target.value)} />
                  <div className="btn-group noselect" >
                    <p style={{ display: "inline", color: "white" }}>เรียงตาม : </p>
                    <button className={(sort === 0 || sort === 1) ? "selected" : ""} onClick={() => sortToggle(0)}>ชื่อ{(sort === 0) ? <MdArrowDropUp /> : ((sort === 1) ? <MdArrowDropDown /> : "")}</button>
                    <button className={(sort === 2 || sort === 3) ? "selected" : ""} onClick={() => sortToggle(1)}>จำนวนการโทร{(sort === 2) ? <MdArrowDropUp /> : ((sort === 3) ? <MdArrowDropDown /> : "")}</button>
                    <button className={(sort === 4 || sort === 5) ? "selected" : ""} onClick={() => sortToggle(2)}>เวลาโทรล่าสุด{(sort === 4) ? <MdArrowDropUp /> : ((sort === 5) ? <MdArrowDropDown /> : "")}</button>
                  </div>
                  <div className="mobile-sort noselect">
                    <button onClick={() => { mobileSort ? setMobileSort(false) : setMobileSort(true) }}><MdSort style={{ fontSize: "30px", color: "white" }} /></button>
                    <div className="mobile-sort-menu" style={mobileSort ? { display: "inline-flex" } : { display: "none" }} onMouseLeave={() => setMobileSort(false)}>
                      <div className="mobile-sort-btn" >
                        <button className={(sort === 0 || sort === 1) ? "selected" : ""} onClick={() => { sortToggle(0) }}>ชื่อ{(sort === 0) ? <MdArrowDropUp /> : ((sort === 1) ? <MdArrowDropDown /> : "")}</button>
                        <button className={(sort === 2 || sort === 3) ? "selected" : ""} onClick={() => { sortToggle(1) }}>จำนวนการโทร{(sort === 2) ? <MdArrowDropUp /> : ((sort === 3) ? <MdArrowDropDown /> : "")}</button>
                        <button className={(sort === 4 || sort === 5) ? "selected" : ""} onClick={() => { sortToggle(2) }}>เวลาโทรล่าสุด{(sort === 4) ? <MdArrowDropUp /> : ((sort === 5) ? <MdArrowDropDown /> : "")}</button>
                        <p style={{ width: "fit-content", alignSelf: "center" }}>แสดงทีละ :</p>
                        <select className="perpage-select-mobile" defaultValue={window.innerWidth > 1365 ? 16 : 6} onChange={e => { setPerPage(parseInt(e.target.value)); setMobileSort(false); setPage(1) }}>
                          {dropUpPreset.reverse().map((ele, i) => { return <option value={ele === 'ทั้งหมด' ? -1 : ele} key={i} >{ele}</option> })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-wrapper" style={(fullscreen) ? { maxHeight: `calc(${"var(--vh, 1vh) * 100 - 125px"})` } : {}}>
                  {!checkNull(cards) ? cards
                    : <div className="noselect" style={{ display: "flex", width: "100%", height: "calc(100vh - 200px)", justifyContent: "center" }}>
                      <h3 style={{
                        padding: "20px 50px", width: "fit-content", borderRadius: "10px", textAlign: "center", backgroundColor: "#ffffff80",
                        display: "flex", alignItems: "center", justifyContent: "center", alignSelf: "center", boxSizing: "border-box"
                      }}>
                        ไม่พบข้อมูล</h3>
                    </div>}
                </div>
                <div className="inner-bar2">
                  <div className="btn-group noselect" >
                    <p style={{ display: "inline", color: "white" }}>แสดงทีละ : </p>
                    <div className="dropup">
                      <button className="dropbtn" style={dropupBtn ? { backgroundColor: "#BCEAF9", borderRadius: "0 0 5px 5px" } : null} onClick={() => { (dropupBtn) ? setDropupBtn(false) : setDropupBtn(true) }} onBlur={() => setDropupBtn(false)}>{window.innerWidth > 1365 ? 16 : 6} &#xf106;</button>
                      <div className="dropup-content" style={dropupBtn ? { display: "block", borderRadius: "5px 5px 0 0" } : { display: "none" }}>
                        {dropUpList}
                      </div>
                    </div>
                    <div className="present">
                      <div className="present-menu" style={(slideMenu) ? { display: "block" } : { display: "none" }}>
                        <button className="close-pmenu" onClick={() => setSlideMenu(false)}>×</button>
                        <div className="btn-group">
                          <h4>โหมดเต็มหน้าจอ&nbsp;</h4>
                          <label className="switch">
                            <input type="checkbox" id="fullscreen-checkbox" checked={fullscreen} onChange={toggleFullScreen} />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="btn-group">
                          <h4>สไลด์โชว์&nbsp;</h4>
                          <label className="switch">
                            <input type="checkbox" id="slideshow-checkbox" onChange={e => { (e.target.checked) ? setSlide(true) : setSlide(false) }} />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <label>
                          <br />เลื่อนทุก
                    <input type="number" id="slideshow-interval" defaultValue={3} min={1} style={{ maxWidth: "80px" }} onInput={e => setInter(e.target.value * 1000)} />
                          <h5 style={{ display: "inline" }}>วินาที</h5>
                        </label>
                      </div>
                      <button className="present-btn" title="โหมดนำเสนอ" onClick={() => { slideMenu ? setSlideMenu(false) : setSlideMenu(true) }} style={(slideMenu) ? { backgroundColor: "#ffffffcc" } : {}} ><RiSlideshow2Line style={{ fontSize: "22px", marginTop: "4px" }} /></button>
                    </div>
                  </div>
                  <div className="pagination noselect" style={{ display: "inline" }}>
                    {(lastPage > 2) ? <button disabled={(page === 1) ? true : false} onClick={() => setPage(1)}>&#xf100;</button> : null}
                    <button disabled={(page === 1 || lastPage <= 0) ? true : false} onClick={() => setPage(page - 1)}>&#xf104;</button>
                    {btnPage}
                    <button disabled={(page === lastPage || lastPage <= 0) ? true : false} onClick={() => setPage(page + 1)}>&#xf105;</button>
                    {(lastPage > 2) ? <button disabled={(page === lastPage) ? true : false} onClick={() => setPage(lastPage)}>&#xf101;</button> : null}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="map noselect" style={map.show? { display: "block"}: { display: "none"}}>
                <div className="map-content">
                  <button className="close" onClick={()=>setMap({show:false,lat:0,lon:0})}>×</button>
                  {(map.lat!=='N/A') && <iframe
                    title="แผนที่แสดงพิกัดการโทรล่าสุด"
                    className="map-api"
                    frameBorder="0"
                    src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyBAfPQMDDIHwTgT8Vd3-mm0aUy9soDJbVI&q="+map.lat+","+map.lon} allowFullScreen>
                  </iframe>}
                  {(map.lat==='N/A') && <div className="map-api2">ไม่สามารถระบุตำแหน่ง</div>}
                </div>
              </div>
              <div className="dialog noselect" style={dialog ? { display: "block" } : { display: "none" }}>
                <div className="dialog-content">
                  <div className="close" onClick={() => setDialog(false)}>×</div>
                  <h4>เลือกฐานข้อมูล</h4>
                  <div>
                    <select id="company" className="company" defaultValue="">
                      <option value="" disabled >กรุณาเลือกฐานข้อมูล...</option>
                      {apiList.map((ele, i) => {
                        if (tab.some(iter => iter.Id === ele.Id) || ele.CompanyName === 'ADMIN') {
                          return null
                        }
                        if (user?.Role === 'admin') {
                          return <option value={ele.Id} key={i}>{ele.CompanyName + ' ' + ele.Branch}</option>
                        } else {
                          return <option value={ele.Id} key={i}>{ele.Branch}</option>
                        }
                      })}
                    </select>
                    <button className="add-db" onClick={addDbCallback}>เพิ่ม</button>
                  </div>
                </div>
              </div>
              <div className="del-dialog noselect" style={closeDialog ? { display: "block" } : { display: "none" }}>
                <div className="dialog-content">
                  <h4>คุณต้องการลบรายการนี้ใช่หรือไม่</h4>
                  <button className="btn cancel" onClick={() => setCloseDialog(false)}>ยกเลิก</button>
                  <button className="btn" onClick={deleteTabCallback}>ลบ</button>
                </div>
              </div>
            </div>
          </SwipeableViews>
        )
      }
    </>
  )
}
