import axios from 'axios'
import {config} from '../config'
const API_URL = config.API_URL + "/auth"
// const API_URL = "/auth"
// const API_URL = "https://www.nsharuk.com:9001/auth"
// const API_URL = "https://moowplay-api.nsharuk.com/auth"
class AuthService{
    login(username, password, storage="session"){
        return axios.post(API_URL + "/login",{
            username: username,
            password: password,
        }).then(res => {
            localStorage.removeItem('user')
            sessionStorage.removeItem('user')
            if(res.data.data?.auth?.token){
                window.sessionStorage.setItem('email',username)
                if(storage==='session'){
                    window.sessionStorage.setItem('user',JSON.stringify(res.data.data.auth))
                } else if (storage === 'local'){
                    window.localStorage.setItem('user',JSON.stringify(res.data.data.auth))
                }
            }
            return res.data
        }).catch(err=>{
            return {error: true, data:{msg: err}}
        })
    }

    logout(history) {
        localStorage.removeItem('user')
        sessionStorage.removeItem('user')
        history.push('/')
    }

    register(obj){
        return axios.post(API_URL + "/register",{
            data: obj,
        }).then(res=>{
            return res.data
        }).catch(err=>{
            return {error: true, msg: err}
        })
    }

    forget(email){
        return axios.post(API_URL + "/forget", {
            email: email
        }).then(res=>{
            return res.data
        }).catch(err=>{
            return {error: true, data:{msg: err}}
        })
    }

    validateResetToken(token){
        return axios.post(API_URL + "/validate_reset", {
            token: token
        }).then(res=>{
            return res.data
        }).catch(err=>{
            return {error: true, data:{msg: err}}
        })
    }

    reset(token, password){
        return axios.post(API_URL + "/password_reset", {
            token : token,
            password: password
        }).then(res=>{
            return res.data
        }).catch(err=>{
            return {error: true, data:{msg: err}}
        })
    }

    changePassword(obj){
        const payload = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
        if(payload)payload.data = obj
        return axios.post(API_URL + "/password_change", payload).then(res=>{
            return res.data
        }).catch(err=>{
            return {error: true, data: {msg: err}}
        })
    }

    currentUser(){
        let payload = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
        return axios({
            method: 'get',
            url: API_URL + '/user',
            headers: payload
        }).then(res=>{
            return res.data
        }).catch(err=>{
            console.log(err)
            return null
        })
    }
}

export default new AuthService()