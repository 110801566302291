function reducer(state = {
  screen: false,
  path: null,
  user: null
}, action) {
  switch (action.type) {
    case 'ENABLE_FULLSCREEN':
      return {
        ...state,
        screen: true
      }
    case 'DISABLE_FULLSCREEN':
      return {
        ...state,
        screen: false
      }
    case 'CHANGE_PATH':
      return {
        ...state,
        path: action.payload
      }
    case 'LOGIN':
      return {
        ...state,
        user: action.payload
      }
    default:
      return state
  }
}

export default reducer