
import React from 'react'
import { MdUpdate, MdCall, MdArrowDropUp } from 'react-icons/md'
import { useEffect, useState } from 'react'
import {config} from '../../../../config'

export const Card = ({ obj, onClick }) => {
  const [path, setPath] = useState(config.API_URL)
  const [error, setError] = useState(true)
  const [error2, setError2] = useState(true)
  const [tooLarge, setLarge] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  function encrypt(text) {
    var hex, i;

    var result = "";
    for (i=0; i<text.length; i++) {
        hex = text.charCodeAt(i).toString(16);
        result += hex;
    }

    return result
  }

  var formatter = new Intl.NumberFormat('th-TH', {
    style: 'decimal',
  })
  function picErrorCallback(e) {
    e.target.style.display = "none"
    setError(true)
    setLarge(false)
  }
  function picLoadedCallback(e) {
    if(e.target.naturalWidth > 400 || e.target.naturalHeight > 400){
      e.target.style.display = "none"
      setError(true)
      setLarge(true)
    } else {
      e.target.style.display = "block"
      setError(false)
      setLarge(false)
    }
  }
  function thumbErrorCallback(e) {
    e.target.style.display = "none"
    setError2(true)
  }
  function thumbLoadedCallback(e) {
    if(e.target.naturalWidth > 400 || e.target.naturalHeight > 400){
      e.target.style.display = "none"
      setError2(true)
    } else {
      e.target.style.display = "block"
      setError2(false)
    }
  }
  function formatDate() {
    const date = new Date(obj.LastCall ? obj.LastCall : obj.update_time)
    const dd = parseInt(date.getDate()) > 9 ? parseInt(date.getDate()) : '0' + parseInt(date.getDate())
    const mm = (parseInt(date.getMonth()) + 1) > 9 ? (parseInt(date.getMonth()) + 1) : '0' + (parseInt(date.getMonth()) + 1)
    const yyyy = ('' + date.getFullYear()).substring(2, 4)
    const hh = parseInt(date.getHours()) > 9 ? parseInt(date.getHours()) : '0' + parseInt(date.getHours())
    const min = parseInt(date.getMinutes()) > 9 ? parseInt(date.getMinutes()) : '0' + parseInt(date.getMinutes())
    return dd + '/' + mm + '/' + yyyy + ' ' + hh + ':' + min + ' น.'
  }

  useEffect(()=>{
    window.addEventListener('resize',(e)=>{
      setWidth(e.target.innerWidth)
    })

    return () => {
      window.removeEventListener('resize',(e)=>{
        setWidth(e.target.innerWidth)
      })
    }
  },[])

  return (
    <div className="card-container" onClick={onClick} title="กดเพื่อดูพิกัดล่าสุดที่โทร">
      {obj.status && <img id={"pimg"} className={error? "profile-pic noselect no-pic" : "profile-pic noselect"} src={path+'/uimg?p=' + encrypt(obj.Path + 'img/' + obj.ProfileImageName)} alt="" style={{ display: 'none' }} onError={picErrorCallback} onLoad={picLoadedCallback} />}
      {(error && obj.status) && <img id={"timg"} className={"profile-pic noselect"} src={path+'/uimg?p=' + encrypt(obj.Path + 'img/thumbnails/' + obj.ProfileImageName)} alt="" style={{ display: 'none' }} onError={thumbErrorCallback} onLoad={thumbLoadedCallback} />}
      {(error && error2) && <div className="profile-pic no-pic noselect">{"ไม่มีรูป"}</div>}
      <div className="details">
        <h3 className="username">{obj.UserName}</h3>
        <div className="centering">
          <h4 className="calls">{window.innerWidth > 1024 && <MdCall />}&nbsp;{formatter.format(obj.Day)}</h4>
          <p className={obj.Difference !== 0 ? "diff green" : "diff red"} title="ส่วนต่างจากครั้งก่อน" >&nbsp;<MdArrowDropUp />{formatter.format(obj.Difference)}</p>
        </div>
        {width <= 1600 && (
          <div className="details3">
              {width > 1260 && <div className="filler"></div>}
              <div className="c">{obj.FirstSection !== 0 ? obj.FirstSection : ""}</div>
              <div className="c">{obj.SecondSection !== 0 ? obj.SecondSection : ""}</div>
          </div>
        )}
        <p className="last-update" title="อัปเดตล่าสุด"><MdUpdate />&nbsp;{formatDate()}</p>
      </div>
      {width > 1600 && (
        <div className="details2">
          <div className="r">
            <div className="c">{obj.FirstSection !== 0 ? obj.FirstSection : ""}</div>
          </div>
          <div className="r">
            <div className="c">{obj.SecondSection !== 0 ? obj.SecondSection : ""}</div>
          </div>
        </div>
      )}
    </div>
  )
}
