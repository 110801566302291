import React from 'react'
import AuthService from '../../../../services/auth.service'
import { Link, useHistory } from 'react-router-dom'
import { MdInfoOutline, MdMenu, MdClose } from 'react-icons/md'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import logo from '../../../../assets/Moow_logo.png'
import noAnnouce from '../../../../assets/background/no-annouce.jpg'
import DataService from '../../../../services/data.service'
import {config} from '../../../../config'

export const NavBar = () => {
  const [path_api, setPath] = useState(config.API_URL)
  const history = useHistory()
  const [menu, setMenu] = useState(false)
  const fullscreen = useSelector(state => state.screen)
  const [user, setUser] = useState()
  const [companyRecord, setCompanyRecord] = useState()
  const path = useSelector(state => state.path)
  const users = useSelector(state => state.user)
  const [lastPath, setLastPath] = useState()
  const [error, setError] = useState(false)
  const [ierror, setIError] = useState(true)

  function encrypt(text) {
    var hex, i;

    var result = "";
    for (i=0; i<text.length; i++) {
        hex = text.charCodeAt(i).toString(16);
        result += hex;
    }

    return result
  }

  useEffect(() => {
    if ((!user && path?.includes('/u')) || !path) {
      AuthService.currentUser().then(user => {
        if (user) {
          setUser(user)
        }
      })
    }
    if (path === "/u" && !lastPath?.includes('/u')) {
      annouceOpen()
    }
    setLastPath(path)
    // eslint-disable-next-line
  }, [users, path])

  useEffect(() => {
    if (user) {
      DataService.getCompanyList().then(res => {
        // console.log(res.find(ele=>ele.Id===user.CompanyId))
        setCompanyRecord(res.find(ele => ele.Id === user.CompanyId))
      })
    }
  }, [user])

  function annouceOpen() {
    if (document.getElementById("annouceModal")) {
      document.getElementById("annouceModal").style.display = "block"
    }
  }

  function annouceClose() {
    if (document.getElementById("annouceModal")) {
      document.getElementById("annouceModal").style.display = "none"
    }
  }

  function toggleMenu() {
    if (menu) {
      setMenu(false)
    }
    else setMenu(true)
  }

  function logoErrorCallback(e) {
    e.target.style.display = "none"
    setError(true)
  }
  function LogoLoadedCallback(e) {
    e.target.style.display = "block"
    setError(false)
  }

  function picErrorCallback(e) {
    e.target.style.display = "none"
    setIError(true)
  }
  function picLoadedCallback(e) {
    e.target.style.display = "block"
    setIError(false)
  }

  return (
    <>
      {
        (!fullscreen && path?.includes('/u'))
        &&
        <div className="UserNavbar">
          <div className="nav-bar">
            <div className="logo-container noselect">
              <img src={logo} alt="MOOW" />
              <div className="bar" style={error ? { backgroundColor: "transparent", margin: 0 } : {}} />
              <img src={path_api + '/uimg?p=' + encrypt(companyRecord?.Path + 'logo')} alt="" onError={logoErrorCallback} onLoad={LogoLoadedCallback} />
              <button className="annouce" title="ประกาศ" onClick={annouceOpen}>
                <MdInfoOutline />
              </button>
            </div>
            <div className="menu noselect">
              <Link to="/u">หน้าหลัก</Link>
              <Link to="/u/password" >เปลี่ยนรหัสผ่าน</Link>
              <Link to="#" onClick={() => { AuthService.logout(history); history.push('/'); }}>ออกจากระบบ</Link>
            </div>
            <div className="mobile-menu">
              <button onClick={toggleMenu}>{menu ? <MdClose style={{ fontSize: "30px", color: "#2EA5BE" }} /> : <MdMenu style={{ fontSize: "30px", color: "#2EA5BE" }} />}</button>
              <div className="menu-content" style={(menu) ? { display: "flex" } : { display: "none" }} onMouseUp={toggleMenu}>
                <Link to="/u">หน้าหลัก</Link>
                <Link to="/u/password">เปลี่ยนรหัสผ่าน</Link>
                <Link to="#" onClick={() => { AuthService.logout(history); history.push('/'); }}>ออกจากระบบ</Link>
              </div>
            </div>
            <div id="annouceModal" className="annouceModal">
              <div className="modal-content">
                <h2 className="noselect" style={{ display: "flex", alignItems: "center" }}><MdInfoOutline />&nbsp;ประกาศ</h2>
                <img src={path_api + '/img/announcement/announce.png'} alt="annoucement" onError={picErrorCallback} onLoad={picLoadedCallback} />
                {ierror && <img src={noAnnouce} alt="no-annoucement" />}
                <button className="close" onClick={annouceClose}>ปิด</button>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}
