// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth.service'
import DataService from '../../../../services/data.service'
import { useHistory } from 'react-router-dom'
import { onPending, detachListener, onUser } from '../../../../services/socket.service'
import './Manage.scss'
import { useDispatch } from 'react-redux'
import { MdCheck, MdClose, MdArrowDropUp, MdArrowDropDown } from 'react-icons/md'

export const AdminManage = () => {
  const history = useHistory()
  const [pendingList, setPendingList] = useState([])
  const [userList, setUserList] = useState([])
  const [renderedPendingList, setRenderedPending] = useState([])
  const [renderedUserList, setRenderedUser] = useState([])
  const [sumInfo, setSumInfo] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)
  const [sort, setSort] = useState(0)
  const [sort2, setSort2] = useState(0)
  const [user, setUser] = useState()
  const [sortList, setSortList] = useState([])
  const dispatch = useDispatch()
  let test = []

  var formatter = new Intl.NumberFormat('th-TH', {
    style: 'decimal',
  })

  useEffect(() => {
    AuthService.currentUser().then(user => {
      setUser(user)
      if (user) {
        if (user.Role !== 'admin') history.push('/u')
      } else {
        AuthService.logout(history)
      }
    })
    dispatch({ type: 'CHANGE_PATH', payload: '/a/manage' })
  }, [])

  useEffect(() => {
    onPending((payload) => {
      setPendingList(payload)
    })
    onUser((payload) => {
      setUserList(payload)
    })

    DataService.getPendingList().then(res => {
      if (res.error) {
        if (res.error_middleware) {
          history.push('/session-expired')
        } else {
          console.log(res)
        }
      }
    })
    DataService.getUserList().then(res => {
      if (res.error) {
        if (res.error_middleware) {
          history.push('/session-expired')
        } else {
          console.log(res)
        }
      }
    })

    return () => {
      detachListener('pending_list')
      detachListener('user_list')
    }
  }, [])

  useEffect(() => {
    if (user) {
      if (sort === 0) userList.sort(dynamicsort("Id", "asc"))
      else if (sort === 1) userList.sort(dynamicsort("Id", "desc"))
      else if (sort === 2) userList.sort(dynamicsort("Email", "asc"))
      else if (sort === 3) userList.sort(dynamicsort("Email", "desc"))
      else if (sort === 4) userList.sort(dynamicsort("CompanyName", "asc"))
      else if (sort === 5) userList.sort(dynamicsort("CompanyName", "desc"))
      else if (sort === 6) userList.sort(dynamicsort("Branch", "asc"))
      else if (sort === 7) userList.sort(dynamicsort("Branch", "desc"))

      if (sort2 === 0) pendingList.sort(dynamicsort("Id", "asc"))
      else if (sort2 === 1) pendingList.sort(dynamicsort("Id", "desc"))
      else if (sort2 === 2) pendingList.sort(dynamicsort("Email", "asc"))
      else if (sort2 === 3) pendingList.sort(dynamicsort("Email", "desc"))
      else if (sort2 === 4) pendingList.sort(dynamicsort("CompanyName", "asc"))
      else if (sort2 === 5) pendingList.sort(dynamicsort("CompanyName", "desc"))
      else if (sort2 === 6) pendingList.sort(dynamicsort("Branch", "asc"))
      else if (sort2 === 7) pendingList.sort(dynamicsort("Branch", "desc"))

      if (userList && pendingList) renderSummary(userList, pendingList)
      if (userList) renderUserList(userList)
      if (pendingList) renderPendingList(pendingList)
    }
  }, [pendingList, userList, sort, sort2, user])

  function toggleActiveCallback(e, id) {
    DataService.toggleActiveUser(id, e.target.checked).then(res => {
      if (res.error) {
        if (res.error_middleware) {
          history.push('/session-expired')
        } else {
          console.log(res)
        }
      }
    })
  }

  function renderPendingList(list) {
    const render = list.map((ele, i) => {
      return <li key={i}>
        <div className="id">{ele.Id}</div>
        <div className="email">{ele.Email}</div>
        <div className="company">{ele.CompanyName}</div>
        <div className="branch">{ele.Branch ? ele.Branch : "-"}</div>
        <div className="status">
          <button className="accept" onClick={() => {
            DataService.acceptPending(ele.Id).then(res => {
              if (res.error) {
                if (res.error_middleware) {
                  history.push('/session-expired')
                } else {
                  console.log(res)
                }
              }
            })
          }}><MdCheck /></button>
          <button className="reject" onClick={() => {
            DataService.declinePending(ele.Id).then(res => {
              if (res.error) {
                if (res.error_middleware) {
                  history.push('/session-expired')
                } else {
                  console.log(res)
                }
              }
            })
          }}><MdClose /></button>
        </div>
      </li>
    })

    setRenderedPending(render)
  }

  function renderUserList(list) {
    const render = list.map((ele, i) => {
      return <li key={i}>
        <div className="id">{ele.Id}</div>
        <div className="email">{ele.Email}</div>
        <div className="company">{ele.CompanyName}</div>
        <div className="branch">{ele.Branch ? ele.Branch : "-"}</div>
        <div className="status">
          {(ele?.Id === user?.Id) ? "-" : (<label className="switch">
            <input type="checkbox" checked={ele.IsActive} onChange={(e) => toggleActiveCallback(e, ele.Id)} />
            <span className="slider round" />
          </label>)}
        </div>
      </li>
    })

    setRenderedUser(render)
  }

  function renderSummary(user, pending) {
    const total_account = user.length + pending.length
    const pending_count = pending.length
    let active_account = 0
    let inactive_account = 0

    user.forEach((ele) => {
      if (ele.IsActive === 0) {
        inactive_account += 1
      } else {
        active_account += 1
      }
    })
    setSumInfo([total_account, pending_count, active_account, inactive_account])
  }

  function sortToggle(sel) {
    if (selectedTab === 0) { // แท็บปัจจุบัน
      if (sel === 0) { // เลือกไอดี
        if (sort === 0) setSort(1) // ▲ ⇒ ▼
        else if (sort === 1) setSort(0) // ▼ ⇒ ▲
        else setSort(0) // มาจากอันอื่น
      }
      if (sel === 1) { // เลือกอีเมล
        if (sort === 2) setSort(3) // ▲ ⇒ ▼
        else if (sort === 3) setSort(2) // ▼ ⇒ ▲
        else setSort(2) // มาจากอันอื่น
      }
      if (sel === 2) { // เลือกบริษัท
        if (sort === 4) setSort(5) // ▲ ⇒ ▼
        else if (sort === 5) setSort(4) // ▼ ⇒ ▲
        else setSort(4) // มาจากอันอื่น
      }
      if (sel === 3) { // เลือกสาขา
        if (sort === 6) setSort(7) // ▲ ⇒ ▼
        else if (sort === 7) setSort(6) // ▼ ⇒ ▲
        else setSort(6) // มาจากอันอื่น
      }
    }
    else if (selectedTab === 1) { // แท็บรออนุมัติ
      if (sel === 0) { // เลือกไอดี
        if (sort2 === 0) setSort2(1) // ▲ ⇒ ▼
        else if (sort2 === 1) setSort2(0) // ▼ ⇒ ▲
        else setSort2(0) // มาจากอันอื่น
      }
      if (sel === 1) { // เลือกอีเมล
        if (sort2 === 2) setSort2(3) // ▲ ⇒ ▼
        else if (sort2 === 3) setSort2(2) // ▼ ⇒ ▲
        else setSort2(2) // มาจากอันอื่น
      }
      if (sel === 2) { // เลือกบริษัท
        if (sort2 === 4) setSort2(5) // ▲ ⇒ ▼
        else if (sort2 === 5) setSort2(4) // ▼ ⇒ ▲
        else setSort2(4) // มาจากอันอื่น
      }
      if (sel === 3) { // เลือกสาขา
        if (sort2 === 6) setSort2(7) // ▲ ⇒ ▼
        else if (sort2 === 7) setSort2(6) // ▼ ⇒ ▲
        else setSort2(6) // มาจากอันอื่น
      }
    }
  }

  function dynamicsort(property, order) {
    var sort_order = 1;
    if (order === "desc") {
      sort_order = -1;
    }
    return function (a, b) {
      if (typeof (a[property]) === "string" || typeof (a[property]) === "string") {
        if (a[property].toLowerCase() < b[property].toLowerCase()) {
          return -1 * sort_order;
        } else if (a[property].toLowerCase() > b[property].toLowerCase()) {
          return 1 * sort_order;
        } else {
          return 0 * sort_order;
        }
      }
      else {
        if (a[property] < b[property]) {
          return -1 * sort_order;
        } else if (a[property] > b[property]) {
          return 1 * sort_order;
        } else {
          return 0 * sort_order;
        }
      }
    }
  }

  return (
    <div className="Manage">
      <div className="container">
        <div className="wrapper">
          <div className="summary">
            <div className="users">
              <h4 className="noselect">จำนวนบัญชีทั้งหมด</h4>
              <h1>{formatter.format(sumInfo[0])}</h1>
            </div>
            <div className="waiting">
              <h4 className="noselect">จำนวนบัญชีรออนุมัติ</h4>
              <h1>{formatter.format(sumInfo[1])}</h1>
            </div>
            <div className="account">
              <h4 className="noselect">จำนวนบัญชีเปิดใช้งาน</h4>
              <h1>{formatter.format(sumInfo[2])}</h1>
            </div>
            <div className="closed">
              <h4 className="noselect">จำนวนบัญชีปิดใช้งาน</h4>
              <h1>{formatter.format(sumInfo[3])}</h1>
            </div>
          </div>
          <div className="list-wrapper">
            <div className="button-wrapper noselect">
              <button className={selectedTab === 0 ? "selected" : ""} onClick={() => setSelectedTab(0)}>บัญชีปัจจุบัน</button>
              <button className={selectedTab === 1 ? "selected" : ""} onClick={() => setSelectedTab(1)}>บัญชีรออนุมัติ</button>
            </div>
            {
              selectedTab === 0 ? (
                <div className="inner-wrap">
                  <li className="table-head noselect">
                    <div className="id" style={{ textAlign: "center" }}> <a onClick={() => sortToggle(0)}>ไอดี{(sort === 0) ? <MdArrowDropUp /> : ((sort === 1) ? <MdArrowDropDown /> : "")}</a> </div>
                    <div className="email" style={{ textAlign: "center" }}> <a onClick={() => sortToggle(1)}>อีเมล{(sort === 2) ? <MdArrowDropUp /> : ((sort === 3) ? <MdArrowDropDown /> : "")}</a> </div>
                    <div className="company" style={{ textAlign: "center" }}> <a onClick={() => sortToggle(2)}>บริษัท{(sort === 4) ? <MdArrowDropUp /> : ((sort === 5) ? <MdArrowDropDown /> : "")}</a> </div>
                    <div className="branch" style={{ textAlign: "center" }}> <a onClick={() => sortToggle(3)}>สาขา{(sort === 6) ? <MdArrowDropUp /> : ((sort === 7) ? <MdArrowDropDown /> : "")}</a> </div>
                    <div className="status" style={{ textAlign: "center" }}>สถานะ</div>
                  </li>
                  <div className="inner-list-wrap">
                    {renderedUserList.length !== 0 ? renderedUserList : (
                      <h3 className="noselect" style={{ width: "100%", textAlign: "center" }}>ไม่พบบัญชี</h3>
                    )}
                  </div>
                </div>
              ) : (
                  <div className="inner-wrap">
                    <li className="table-head noselect">
                      <div className="id" style={{ textAlign: "center" }}> <a onClick={() => sortToggle(0)}>ไอดี{(sort2 === 0) ? <MdArrowDropUp /> : ((sort2 === 1) ? <MdArrowDropDown /> : "")}</a> </div>
                      <div className="email" style={{ textAlign: "center" }}> <a onClick={() => sortToggle(1)}>อีเมล{(sort2 === 2) ? <MdArrowDropUp /> : ((sort2 === 3) ? <MdArrowDropDown /> : "")}</a> </div>
                      <div className="company" style={{ textAlign: "center" }}> <a onClick={() => sortToggle(2)}>บริษัท{(sort2 === 4) ? <MdArrowDropUp /> : ((sort2 === 5) ? <MdArrowDropDown /> : "")}</a> </div>
                      <div className="branch" style={{ textAlign: "center" }}> <a onClick={() => sortToggle(3)}>สาขา{(sort2 === 6) ? <MdArrowDropUp /> : ((sort2 === 7) ? <MdArrowDropDown /> : "")}</a> </div>
                      <div className="status" style={{ textAlign: "center" }}>อนุมัติ</div>
                    </li>
                    <div className="inner-list-wrap">
                      {renderedPendingList.length !== 0 ? renderedPendingList : (
                        <h3 className="noselect" style={{ width: "100%", textAlign: "center" }}>ไม่พบบัญชีรอการอนุมัติ</h3>
                      )}
                    </div>
                  </div>
                )
            }
          </div>
        </div>
      </div>
    </div>
  )
}