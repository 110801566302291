// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth.service'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AdminCard } from '../Components'
import { MdWarning } from 'react-icons/md'
import { FaSyncAlt, FaUserClock } from 'react-icons/fa'
import './Home.scss'
import DataService from '../../../../services/data.service'
import { detachListener, onAllRecord, onPending } from '../../../../services/socket.service'
import { RiContactsBookLine } from 'react-icons/ri'

export const AdminHome = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(0)
  const [user, setUser] = useState()
  const [data, setData] = useState([])
  const [clist, setclist] = useState([])
  const [cardRenderer, setCardRenderer] = useState([])
  const [pendingList, setPendingList] = useState([])
  const [disableUpdate, setDisableUpdate] = useState(false)

  var formatter = new Intl.NumberFormat('th-TH', {
    style: 'decimal',
  })

  useEffect(() => {
    AuthService.currentUser().then(user => {
      if (user) {
        if (user.Role !== 'admin') history.push('/u')
        else {
          setUser(user)
        }
      } else {
        AuthService.logout(history)
      }
    })
    dispatch({ type: 'CHANGE_PATH', payload: '/a' })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (user) {
      onPending((payload) => {
        setPendingList(payload)
      })

      DataService.getCompanyList().then(res => {
        setclist(res)
      })
      DataService.getPendingList().then(res => {
        if (res.error) {
          if (res.error_middleware) {
            history.push('/session-expired')
          } else {
            console.log(res)
          }
        }
      })
    }
    return () => {
      detachListener('pending_list')
    }
  }, [user])

  useEffect(() => {
    if (clist.length > 0) {
      onAllRecord(payload => {
        if (payload) {
          // console.log(payload)
          setDisableUpdate(false)
          setData(payload.sort((a, b) => a.CompanyId - b.CompanyId))
          // console.log(payload)
        }
      })

      DataService.getAllRecord().then(res => {
        if (res.error) {
          if (res.error_middleware) {
            history.push('/session-expired')
          } else {
            console.log(res)
          }
        }
      })
    }
    return () => {
      detachListener('company_all')
    }
    // eslint-disable-next-line
  }, [clist])

  useEffect(() => {
    renderData(data, filter)
    // eslint-disable-next-line
  }, [data, filter])

  function renderData(data, filter) {
    setCardRenderer(data.map((ele, i) => {
      ele.CompanyRecord = clist.filter(iter => iter.Id === ele.CompanyId)[0]
      // console.log(ele)
      if (filter === 1) {
        if (ele.ServerStatus === 1 && ele.Data.diff !== 0) {
          return <AdminCard obj={ele} key={i} />
        }
        else return null
      }
      else if (filter === 2) {
        if (ele.ServerStatus === 1 && ele.Data.diff === 0) {
          return <AdminCard obj={ele} key={i} />
        }
        else return null
      }
      else if (filter === 3) {
        if (ele.ServerStatus === 0) {
          return <AdminCard obj={ele} key={i} />
        }
        else return null
      }
      else return <AdminCard obj={ele} key={i} />
    }))
  }

  function formatDate(timestr) {
    const date = new Date(timestr)
    const dd = parseInt(date.getDate()) > 9 ? parseInt(date.getDate()) : '0' + parseInt(date.getDate())
    const mm = (parseInt(date.getMonth()) + 1) > 9 ? (parseInt(date.getMonth()) + 1) : '0' + (parseInt(date.getMonth()) + 1)
    const yyyy = ('' + date.getFullYear()).substring(2, 4)
    const hh = parseInt(date.getHours()) > 9 ? parseInt(date.getHours()) : '0' + parseInt(date.getHours())
    const min = parseInt(date.getMinutes()) > 9 ? parseInt(date.getMinutes()) : '0' + parseInt(date.getMinutes())
    return dd + '/' + mm + '/' + yyyy + ' ' + hh + ':' + min + ' น.'
  }

  function updateCallback() {
    if (!disableUpdate) {
      setDisableUpdate(true)
      DataService.fetch_api().then(res => {
        if (res.error) {
          if (res.error_middleware) {
            history.push('/session-expired')
          } else {
            console.log(res)
          }
        }
      })
    }
  }

  function checkNull(list) {
    for (let i = 0; i < list.length; i++) {
      if (list[i] !== null) return false
    }
    return true
  }

  return (
    <div className="Admin-Home">
      <div className="summary-wrapper">
        <div className="company">
          <h4>จำนวนบริษัท</h4>
          <h2>{formatter.format(data.length)}</h2>
        </div>
        <div className="account">
          <h4>จำนวนผู้ใช้</h4>
          <h2>{formatter.format(data.reduce((prev, next) => {
            return prev + (next.Data.user ? next.Data.user.length : 0)
          }, 0))}</h2>
        </div>
        <div className="file">
          <h4>จำนวนไฟล์</h4>
          <h2>{formatter.format(data.reduce((prev, next) => {
            return prev + (next.Data.total ? next.Data.total : 0)
          }, 0))}</h2>
        </div>
        <div className="waiting">
          <h4>ผู้ใช้รออนุมัติ</h4>
          <h2><FaUserClock style={{ color: "#00A1E5" }} />&nbsp;{formatter.format(pendingList.length)}</h2>
        </div>
        <div className="online">
          <h4>ออนไลน์</h4>
          <h2>
            <span className="dot" style={{ display: "inline-flex", backgroundColor: "#7DD96E", borderRadius: "50%", width: "15px", height: "15px" }} />
            &nbsp;{formatter.format(data.reduce((prev, next) => {
              return prev + next.ServerStatus
            }, 0))}
          </h2>
        </div>
        <div className="offline">
          <h4>ผิดปกติ/ออฟไลน์</h4>
          <h2><MdWarning style={{ color: "#FF3D3D" }} />&nbsp;{formatter.format(data.reduce((prev, next) => {
            return prev + (next.ServerStatus === 1 ? (next.Data.diff === 0 ? 1 : 0) : 1)
          }, 0))}</h2>
        </div>
        <h4 className="update"><FaSyncAlt className={disableUpdate ? "sync noselect disable" : "sync noselect"} onClick={updateCallback} /><b className={disableUpdate ? "noselect disable" : "noselect"} onClick={updateCallback}>&nbsp;อัปเดตล่าสุด&nbsp;</b>{data[0] ? formatDate(data[0].UpdateDate) : ''}</h4>
      </div>
      <div className="content">
        <div className="inner-bar">
          <div className="btn-group noselect" >
            <p style={{ color: "#535353", marginRight: "10px" }}>ตัวกรองสถานะ</p>
            <button className={filter === 0 ? "selected" : ""} onClick={() => setFilter(0)} style={{ color: "#00536D" }}>ทั้งหมด</button>
            <button className={filter === 1 ? "selected" : ""} onClick={() => setFilter(1)} style={{ color: "#68C253" }}>ปกติ</button>
            <button className={filter === 2 ? "selected" : ""} onClick={() => setFilter(2)} style={{ color: "#FF4747" }}>ผิดปกติ</button>
            <button className={filter === 3 ? "selected" : ""} onClick={() => setFilter(3)} style={{ color: "grey" }}>ออฟไลน์</button>
          </div>
        </div>
        <div className="card-wrapper">
          {!checkNull(cardRenderer) ? cardRenderer
            : <h3 className="noselect" style={{
              width: "100%", height: "141px", textAlign: "center",
              display: "flex", alignItems: "center", justifyContent: "center"
            }}>
              ไม่พบฐานข้อมูลอยู่ในสถานะที่เลือก</h3>}
        </div>
      </div>
    </div>
  )
}