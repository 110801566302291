import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import AuthService from '../../../../services/auth.service'
import './Password.scss'
import { useDispatch } from 'react-redux'
import logo from '../../../../assets/Moowplay_logo.svg'

export const AdminPassword = () => {
  const history = useHistory()
  const [dialog, setDialog] = useState(false)
  const dispatch = useDispatch()
  const [msg, setMsg] = useState('')

  useEffect(() => {
    AuthService.currentUser().then(user => {
      if (user) {
        if (user.Role !== 'admin') history.push('/u')
      } else {
        AuthService.logout(history)
      }
    })
    dispatch({ type: 'CHANGE_PATH', payload: '/a/password' })
  }, [])

  const [oldPassword, setOldPassword] = useState()
  function oldPasswordCallback(e) {
    setOldPassword(e.target.value)
    if (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>_+-]{6,}$/.test(e.target.value) || e.target.value.length === 0) {
      alert('.old-password', '.', false)
      e.target.value.length !== 0 ? document.querySelector('.old-password').classList.add('pass') : document.querySelector('.old-password').classList.remove('pass')
    } else {
      document.querySelector('.old-password').classList.remove('pass')
    }
  }

  const [password, setPassword] = useState()
  function passwordCallback(e) {
    setPassword(e.target.value)
    if (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>_+-]{6,}$/.test(e.target.value) || e.target.value.length === 0) {
      alert('.password', '.', false)
      e.target.value.length !== 0 ? document.querySelector('.password').classList.add('pass') : document.querySelector('.password').classList.remove('pass')
    } else {
      alert('.password', 'ตัวอักษรภาษาอังกฤษ ตัวเลข หรืออักขระพิเศษ รวมกัน 6 ตัวขึ้นไป')
      document.querySelector('.password').classList.remove('pass')
    }
  }

  const [confirmPassword, setConfirmPassword] = useState()
  function confirmPasswordCallback(e) {
    setConfirmPassword(e.target.value)
    if ((validate('.password') && password === e.target.value) || e.target.value.length === 0) {
      alert('.confirm-password', '.', false)
      e.target.value.length !== 0 ? document.querySelector('.confirm-password').classList.add('pass') : document.querySelector('.confirm-password').classList.remove('pass')
    } else {
      alert('.confirm-password', 'รหัสผ่านไม่ตรงกัน')
      document.querySelector('.confirm-password').classList.remove('pass')
    }
  }

  function validate(identifier) {
    const ref = document.querySelector(identifier)
    return ref.classList.contains('pass')
  }

  function promptEmpty(identifier) {
    const ref = document.querySelector(identifier)
    if (!ref.classList.contains('alert')) {
      ref.classList.add('alert')
      //Set alert msg to กรุณากรอกข้อมูล
      document.querySelector(identifier + '-alert').classList.add('show')
      document.querySelector(identifier + '-alert').innerHTML = 'กรุณากรอกข้อมูล'
    }
  }

  function alert(identifier, message = '.', on = true) {
    const boxRef = document.querySelector(identifier)
    const msgRef = document.querySelector(identifier + '-alert')
    if (on) {
      boxRef.classList.add('alert')
      msgRef.classList.add('show')
      msgRef.innerHTML = message
    } else {
      boxRef.classList.remove('alert')
      msgRef.classList.remove('show')
      msgRef.innerHTML = message
    }
  }

  function resetCallback(e) {
    e.preventDefault()

    if (validate('.old-password') && validate('.password') && validate('.confirm-password')) {
      AuthService.changePassword({
        oldPassword: oldPassword,
        password: password
      }).then(res => {
        console.log(res)
        if (!res.error) {
          setMsg(res.data.msg)
          setDialog(true)
          clearField()
        } else {
          if (res.data.msg === 'รหัสผ่านไม่ถูกต้อง') {
            alert('.old-password', res.data.msg)
            document.querySelector('.old-password').classList.remove('pass')
          } else if (res.data.msg === 'เปลี่ยนรหัสผ่านล้มเหลว กรุณาลองอีกครั้ง') {
            setMsg(res.data.msg)
            setDialog(true)
          } else {
            history.push('/session-expired')
          }
        }
      })
    } else {
      console.log('Some Field Invalid!')
      if (!validate('.old-password')) {
        promptEmpty('.old-password')
      }
      if (!validate('.password')) {
        promptEmpty('.password')
      }
      if (!validate('.confirm-password')) {
        promptEmpty('.confirm-password')
      }
    }
  }

  function clearField() {
    document.getElementById('opwd').value = ''
    document.getElementById('pwd').value = ''
    document.getElementById('cpwd').value = ''
    alert('.old-password', '.', false)
    alert('.password', '.', false)
    alert('.confirm-password', '.', false)
    document.querySelector('.old-password').classList.remove('pass')
    document.querySelector('.password').classList.remove('pass')
    document.querySelector('.confirm-password').classList.remove('pass')
  }

  return (
    <div className="AdminPassword">
      <div className="bgWrapper"></div>
      <div className="container">
        <div className="wrapper" >
          <img className="noselect" src={logo} alt="MOOWPLAY" />
          <h3 className="sub-title noselect">เปลี่ยนรหัสผ่าน</h3>
          <p className="old-password-alert">.</p>
          <input id="opwd" className="old-password" type="password" onChange={oldPasswordCallback} onKeyPress={(e) => { if (e.keyCode === 13 || e.which === 13) { resetCallback(e) } }} placeholder="รหัสผ่านเดิม"></input>
          <p className="password-alert">.</p>
          <input id="pwd" className="password" type="password" onChange={passwordCallback} onKeyPress={(e) => { if (e.keyCode === 13 || e.which === 13) { resetCallback(e) } }} placeholder="รหัสผ่านใหม่"></input>
          <p className="confirm-password-alert">.</p>
          <input id="cpwd" className="confirm-password" type="password" onChange={confirmPasswordCallback} onKeyPress={(e) => { if (e.keyCode === 13 || e.which === 13) { resetCallback(e) } }} placeholder="ยืนยันรหัสผ่านใหม่"></input>
          <button className="reset-btn noselect" onClick={resetCallback}>เปลี่ยนรหัสผ่าน</button>
        </div>
      </div>
      <div className="dialog" style={dialog ? { display: "flex" } : { display: "none" }}>
        <div className="dialog-content">
          <button className="close" onClick={() => setDialog(false)}>&times;</button>
          <h4>{msg}</h4>
        </div>
      </div>
    </div>
  )
}