import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { HashRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom'
// eslint-disable-next-line
import { Provider } from 'react-redux'
import { Login, Register, Reset, Forget, InvalidReset, SessionTimeout} from './components/public'
import { AdminHome, AdminManage, AdminPassword} from './components/private/admin'
import { UserHome, UserPassword} from './components/private/user'
import './App.scss'
import { NavBar } from './components/private/user/Components';
import { AdminNavBar } from './components/private/admin/Components'
import store from './store/store'
import { isMobileOnly } from 'react-device-detect';

const App = () => {
  const [showBlue, setShow] = useState(false)

  useEffect(()=>{
    if(!(isMobileOnly && (window.matchMedia("(orientation: landscape)").matches))){
      setShow(false)
    } else {
      // setShow(true)
    }
  // eslint-disable-next-line
  },[])

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    if(!(isMobileOnly && (window.matchMedia("(orientation: landscape)").matches))){
      setShow(false)
    } else {
      // setShow(true)
    }
  })

  window.onorientationchange = function() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    if(!(isMobileOnly && (window.matchMedia("(orientation: landscape)").matches))){
      setShow(false)
    } else {
      // setShow(true)
    }
  }

  window.onclick = function (event) {
    if (document.getElementById("annouceModal")) {
      if (event.target === document.getElementById("annouceModal")) {
        document.getElementById("annouceModal").style.display = "none";
      }
    }
  }

  return (
    <Provider store={store}>
      <Router>
        {showBlue && (<div className="rotate">
          <div className="phone"><img src={require("./assets/background/phone_rotate.png")} alt="phone_rotate" width="90"/></div>
          <div className="notice">กรุณาหมุนหน้าจอเป็นแนวตั้ง</div>
        </div>)}
        {!showBlue &&
        <NavBar id="navbar" />}
        {!showBlue &&
        <AdminNavBar id="admin-navbar" />}
        <Switch>
          <Route path="/login">
          {!showBlue &&
            <Login />}
          </Route>
          <Route path="/register">
          {!showBlue &&
            <Register />}
          </Route>
          <Route path="/forget">
          {!showBlue &&
            <Forget />}
          </Route>
          <Route path="/reset/:resetToken">
          {!showBlue &&
            <Reset />}
          </Route>
          <Route path="/invalid-reset">
          {!showBlue &&
            <InvalidReset />}
          </Route>
          <Route path="/session-expired">
          {!showBlue &&
            <SessionTimeout />}
          </Route>
          <Route path="/a/manage">
          {!showBlue &&
            <AdminManage />}
          </Route>
          <Route path="/a/password">
          {!showBlue &&
            <AdminPassword />}
          </Route>
          <Route path="/u/password">
          {!showBlue &&
            <UserPassword />}
          </Route>
          <Route path="/a">
          {!showBlue &&
            <AdminHome />}
          </Route>
          <Route path="/u">
          {!showBlue &&
            <UserHome />}
          </Route>
          <Route path="/">
            <Redirect to="/login" />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
