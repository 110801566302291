// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AuthService from '../../../services/auth.service'
import { useDispatch } from 'react-redux'
import logo from '../../../assets/Moowplay_logo.svg'

export const Forget = () => {
  const [dialog, setDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'CHANGE_PATH', payload: '/forget' })
  })

  function emailCallback(e) {
    setEmail(e.target.value)
    if (/\S+@\S+\.\S+/.test(e.target.value) || e.target.value.length === 0) {
      alert('.email', '.', false)
      e.target.value.length !== 0 ? document.querySelector('.email').classList.add('pass') : document.querySelector('.email').classList.remove('pass')
    } else {
      alert('.email', 'กรุณากรอกอีเมลที่ถูกต้อง')
      document.querySelector('.email').classList.remove('pass')
    }
  }

  function forgetCallback(e) {
    if (validate('.email')) {
      setLoading(true)
      AuthService.forget(email).then(res => {
        console.log(res)
        if (res.error) {
          alert('.email', res.data.msg)
          document.querySelector('.email').classList.remove('pass')
          setLoading(false)
        }
        else {
          setDialog(true)
          setLoading(false)
        }
      })
    } else {
      if (!validate('.email')) {
        promptEmpty('.email')
      }
    }

    e.preventDefault()
  }

  function validate(identifier) {
    const ref = document.querySelector(identifier)
    return ref.classList.contains('pass')
  }

  function promptEmpty(identifier) {
    const ref = document.querySelector(identifier)
    if (!ref.classList.contains('alert')) {
      ref.classList.add('alert')
      //Set alert msg to กรุณากรอกข้อมูล
      document.querySelector(identifier + '-alert').classList.add('show')
      document.querySelector(identifier + '-alert').innerHTML = 'กรุณากรอกข้อมูล'
    }
  }

  function alert(identifier, message = '.', on = true) {
    const boxRef = document.querySelector(identifier)
    const msgRef = document.querySelector(identifier + '-alert')
    if (on) {
      boxRef.classList.add('alert')
      msgRef.classList.add('show')
      msgRef.innerHTML = message
    } else {
      boxRef.classList.remove('alert')
      msgRef.classList.remove('show')
      msgRef.innerHTML = message
    }
  }

  return (
    <div className="Forget">
      <div className="bgWrapper"></div>
      <div className="container">
        <form className="wrapper" >
          <img className="noselect" src={logo} alt="MOOWPLAY" />
          <h3 className="sub-title noselect">ลืมรหัสผ่าน</h3>
          <p className="email-alert">.</p>
          <input autoFocus className="email" type="email" placeholder="อีเมล" onChange={emailCallback} required />
          <button className="forget-btn noselect" onClick={forgetCallback} disabled={dialog}>ส่งอีเมล</button>
          <p className="login noselect" ><Link to="/login">เข้าสู่ระบบ</Link></p>
        </form>
      </div>
      <div className="loading" style={loading ? { display: "block" } : { display: "none" }}>
        <div className="loading-content">
          <div className="loader"></div>
        </div>
      </div>
      <div className="dialog" style={dialog ? { display: "block" } : { display: "none" }}>
        <div className="dialog-content">
          <button className="close" onClick={() => setDialog(false)}>×</button>
          <h4>ระบบได้ส่งอีเมลสำหรับรีเซ็ตรหัสผ่านให้ท่านแล้ว<br />กรุณาตรวจสอบอีเมลของท่าน</h4>
        </div>
      </div>
    </div>
  )
}