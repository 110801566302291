import socketIOCLient from 'socket.io-client'
import {config} from '../config'
const endpoint = config.API_URL
// const endpoint = "https://moowplay-api.nsharuk.com"
// const endpoint = "https://www.nsharuk.com:9001"
// const endpoint = "localhost:9001"
const socket = socketIOCLient(endpoint)
export const onPending = (callback) => {
    socket.on('pending_list', payload => {
            callback(payload)
    })
}
export const onUser = (callback)=>{
    socket.on('user_list', payload =>{
        callback(payload)
    })
}
export const onLastRecord = (callback, companyId) => {
    socket.on('Company_'+companyId, payload => {
            callback(payload)
    })
}

export const onAllRecord = (callback) => {
    socket.on('company_all', payload => {
            callback(payload)
    })
}

export const detachListener = (listener)=>{
    socket.off(listener)
}

export const connectSocket = () => {
    socket.connect(endpoint)
}

export const onUserSetting = (callback, userId, companyId) => {
    socket.on("User_"+userId+"_Company_"+ companyId, payload =>{
        callback(payload)
    })
}

export const onCompanyStatus = (callback)=>{
    socket.on('cstat',payload=>{
        callback(payload)
    })
}