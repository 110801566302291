import React from 'react'
import AuthService from '../../../../services/auth.service'
import { Link, useHistory } from 'react-router-dom'
import { MdMenu, MdClose } from 'react-icons/md'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import logo from '../../../../assets/Moow_logo.png'

export const AdminNavBar = () => {
  const history = useHistory()
  const [menu, setMenu] = useState(false)
  const fullscreen = useSelector(state => state.screen)
  const [user, setUser] = useState()
  const path = useSelector(state => state.path)
  const users = useSelector(state => state.user)

  useEffect(() => {
    if ((!user && path?.includes('/a')) || !path) {
      AuthService.currentUser().then(user => {
        if (user) {
          setUser(user)
        }
      })
    }
  }, [users, path])

  function toggleMenu() {
    if (menu) {
      setMenu(false)
    }
    else setMenu(true)
  }

  return (
    <>
      {
        !fullscreen && path?.includes("/a")
        &&
        <div className="AdminNavbar">
          <div className="nav-bar">
            <div className="logo-container noselect">
              <img src={logo} alt="MOOW" />
              <div className="bar"></div>
              <h2>Admin</h2>
            </div>
            <div className="menu noselect">
              <Link to="/a">หน้าหลัก</Link>
              <Link to="/a/manage" >จัดการสมาชิก</Link>
              <Link to="/a/password" >เปลี่ยนรหัสผ่าน</Link>
              <Link to="#" onClick={() => { AuthService.logout(history); history.push('/'); }}>ออกจากระบบ</Link>
            </div>
            <div className="mobile-menu" onMouseUp={() => setMenu(false)}>
              <button onClick={toggleMenu}>{menu ? <MdClose style={{ fontSize: "30px", color: "#2EA5BE" }} /> : <MdMenu style={{ fontSize: "30px", color: "#2EA5BE" }} />}</button>
              <div className="menu-content" style={(menu) ? { display: "flex" } : { display: "none" }}>
                <Link to="/a">หน้าหลัก</Link>
                <Link to="/a/manage" >จัดการสมาชิก</Link>
                <Link to="/a/password">เปลี่ยนรหัสผ่าน</Link>
                <Link to="#" onClick={() => { AuthService.logout(history); }}>ออกจากระบบ</Link>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}
