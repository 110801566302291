// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import AuthService from '../../../services/auth.service'
import { useDispatch } from 'react-redux'
import logo from '../../../assets/Moowplay_logo.svg'

export const Reset = () => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [dialog, setDialog] = useState(false)
  let { resetToken } = useParams()
  const dispatch = useDispatch()

  useEffect(()=>{
    AuthService.validateResetToken(resetToken).then(res=>{
      if(res.error){
        history.push('/invalid-reset')
      }
    })
    dispatch({ type: 'CHANGE_PATH', payload: '/reset' })
  },[])

  const [password, setPassword] = useState()
  function passwordCallback(e) {
    setPassword(e.target.value)
    if (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>_+-]{6,}$/.test(e.target.value) || e.target.value.length === 0) {
      alert('.password', '.', false)
      e.target.value.length !== 0 ? document.querySelector('.password').classList.add('pass') : document.querySelector('.password').classList.remove('pass')
    } else {
      alert('.password', 'ภาษาอังกฤษ ตัวเลข และอักขระพิเศษ ตั้งแต่ 6 ตัวขึ้นไป')
      document.querySelector('.password').classList.remove('pass')
    }
  }

  const [confirmPassword, setConfirmPassword] = useState()
  function confirmPasswordCallback(e) {
    setConfirmPassword(e.target.value)
    if ((validate('.password') && password === e.target.value) || e.target.value.length === 0) {
      alert('.confirm-password', '.', false)
      e.target.value.length !== 0 ? document.querySelector('.confirm-password').classList.add('pass') : document.querySelector('.confirm-password').classList.remove('pass')
    } else {
      alert('.confirm-password', 'รหัสผ่านไม่ตรงกัน')
      document.querySelector('.confirm-password').classList.remove('pass')
    }
  }

  function validate(identifier) {
    const ref = document.querySelector(identifier)
    return ref.classList.contains('pass')
  }

  function promptEmpty(identifier) {
    const ref = document.querySelector(identifier)
    if (!ref.classList.contains('alert')) {
      ref.classList.add('alert')
      //Set alert msg to กรุณากรอกข้อมูล
      document.querySelector(identifier + '-alert').classList.add('show')
      document.querySelector(identifier + '-alert').innerHTML = 'กรุณากรอกข้อมูล'
    }
  }

  function alert(identifier, message = '.', on = true) {
    const boxRef = document.querySelector(identifier)
    const msgRef = document.querySelector(identifier + '-alert')
    if (on) {
      boxRef.classList.add('alert')
      msgRef.classList.add('show')
      msgRef.innerHTML = message
    } else {
      boxRef.classList.remove('alert')
      msgRef.classList.remove('show')
      msgRef.innerHTML = message
    }
  }

  function resetCallback(e) {
    e.preventDefault()
    if (validate('.password') && validate('.confirm-password')) {
      setLoading(true)
      AuthService.reset(resetToken, password).then(res=>{
        console.log(res)
        if(!res.error) setDialog(true)
      setLoading(false)
      })
    } else {
      console.log('Some Field Invalid!')
      if (!validate('.password')) {
        promptEmpty('.password')
      }
      if (!validate('.confirm-password')) {
        promptEmpty('.confirm-password')
      }
    }
  }

  return (
    <div className="Reset">
      <div className="bgWrapper"></div>
      <div className="container">
        <div className="wrapper" >
        <img className="noselect" src={logo} alt="MOOWPLAY" />
          <h3 className="sub-title noselect">รีเซ็ตรหัสผ่าน</h3>
          <p className="password-alert">.</p>
          <input className="password" type="password" onChange={passwordCallback} onKeyPress={(e) => { if (e.keyCode === 13 || e.which === 13) { resetCallback(e) } }} placeholder="รหัสผ่าน"></input>
          <p className="confirm-password-alert">.</p>
          <input className="confirm-password" type="password" onChange={confirmPasswordCallback} onKeyPress={(e) => { if (e.keyCode === 13 || e.which === 13) { resetCallback(e) } }} placeholder="ยืนยันรหัสผ่าน"></input>
          <button className="reset-btn noselect" onClick={resetCallback}>รีเซ็ตรหัสผ่าน</button>
        </div>
      </div>
      <div className="loading" style={loading ? { display: "block" } : { display: "none" }}>
        <div className="loading-content">
          <div className="loader"></div>
        </div>
      </div>
      <div className="dialog" style={dialog ? { display: "flex" } : { display: "none" }}>
        <div className="dialog-content">
          <h4>ตั้งรหัสผ่านใหม่สำเร็จ</h4>
          <Link to="/login">กลับเข้าสู่ระบบ</Link>
        </div>
      </div>
    </div>
  )
}