import axios from 'axios'
import {config} from '../config'
const API_URL_b = config.API_URL
const API_URL = API_URL_b + "/data"
const API_KEY = config.API_KEY
// const API_URL = "/data"
// const API_URL = "https://www.nsharuk.com:9001/data"
// const API_URL = "https://moowplay-api.nsharuk.com/data"
// const API_URL_b = "https://moowplay-api.nsharuk.com"
// const API_KEY = "448f57d10bd1bd423db1abdb8f45e556"
class DataService{
    getCompanyList(){
        return axios({
            method: 'get',
            url: API_URL + '/clist',
            headers:{apikey: API_KEY}
        }).then(res => {
            return res.data
        }).catch(err => {
            return []
        })
    }

    getUserList(){
        const payload = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
        return axios.post(API_URL + '/user_list',payload).then(res=>{
            return res.data
        }).catch(err=>{
            return {error: true, msg: err}
        })
    }

    toggleActiveUser(id,value){
        const payload = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
        if(payload)payload.Id = id
        if(payload)payload.Value = value
        return axios.post(API_URL + '/user_toggle',payload).then(res => {
            return res.data
        }).catch(err=>{
            return {error:true, msg:err}
        })
    }

    getPendingList(){
        const payload = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
        return axios.post(API_URL + '/pending_list',payload).then(res=>{
            return res.data
        }).catch(err=>{
            return {error: true, msg: err}
        })
    }

    acceptPending(id){
        const payload = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
        if(payload)payload.Id = id
        return axios.post(API_URL + '/pending_accept',payload).then(res => {
            return res.data
        }).catch(err=>{
            return {error: true, msg: err}
        })
    }

    declinePending(id){
        const payload = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
        if(payload)payload.Id = id
        return axios.post(API_URL + '/pending_decline',payload).then(res => {
            return res.data
        }).catch(err=>{
            return {error: true, msg: err}
        })
    }

    getLastRecord(companyId){
        const payload = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
        if(payload)payload.company_id = companyId
        return axios.post(API_URL + '/company_last',payload).then(res=>{
            return res.data
        }).catch(err=>{
            return {error: true, msg: err}
        })
    }

    getAllRecord(){
        const payload = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
        return axios.post(API_URL + '/company_all',payload).then(res=>{
            return res.data
        }).catch(err=>{
            return {error: true, msg: err}
        })
    }

    fetch_api(){
        const payload = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
        return axios.post(API_URL + '/fetch_api',payload).then(res=>{
            return res.data
        }).catch(err=>{
            return {error: true, msg: err}
        })
    }

    updateUserSetting(companyId, userId, setting){
        const payload = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
        if(payload)payload.company_id = companyId
        if(payload)payload.user_id = userId
        if(payload)payload.setting = setting
        return axios.post(API_URL + '/update_user_setting',payload).then(res=>{
            return res.data
        }).catch(err=>{
            return {error: true, msg: err}
        })
    }

    getUserSetting(companyId, userId){
        const payload = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
        if(payload)payload.company_id = companyId
        if(payload)payload.user_id = userId
        return axios.post(API_URL + '/user_setting',payload).then(res=>{
            return res.data
        }).catch(err=>{
            return {error: true, msg: err}
        })
    }

    download(id){
        window.open(API_URL_b + '/download/'+id)
    }
}

export default new DataService()