// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth.service'
import { useHistory } from 'react-router-dom'
import logo from '../../../assets/Moowplay_logo.svg'

export const SessionTimeout = () => {
  const [count, setCount] = useState(3)
  const history = useHistory()

  useEffect(() => {
    if (count > -1) setTimeout(() => setCount(count - 1), 1000)
    if (count < 0) AuthService.logout(history)

    return () => { }
  }, [count])

  return (
    <div className="SessionTimeout">
      <div className="bgWrapper"></div>
      <div className="container">
        <div className="wrapper" >
          <img className="noselect" src={logo} alt="MOOWPLAY" />
          <h2 className="sub-title noselect">เซสชั่นหมดอายุ</h2>
          <h4 className="sub-title noselect">กลับหน้าเข้าสู่ระบบ {count}</h4>
        </div>
      </div>
    </div>
  )
}