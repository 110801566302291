import React from 'react'
import logo from '../../../assets/Moowplay_logo.svg'

export const InvalidReset = () => {
  return (
    <div className="InvalidReset">
      <div className="bgWrapper"></div>
      <div className="container">
        <div className="wrapper" >
          <img className="noselect" src={logo} alt="MOOWPLAY" />
          <h2 className="sub-title noselect">ไม่พบหน้าที่ท่านเข้าถึง</h2>
          <h4 className="sub-title noselect">หรือลิงก์หมดอายุ</h4>
        </div>
      </div>
    </div>
  )
}