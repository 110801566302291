import React, { useState } from 'react'
import { MdHistory, MdNoteAdd, MdArrowDropUp } from 'react-icons/md'
import { FaUserCheck, FaUsers } from 'react-icons/fa'
import {config} from '../../../../config'

export const AdminCard = ({ obj }) => {
  const [path, setPath] = useState(config.API_URL)
  const [error, setError] = useState(true)
  
  function encrypt(text) {
    var hex, i;

    var result = "";
    for (i=0; i<text.length; i++) {
        hex = text.charCodeAt(i).toString(16);
        result += hex;
    }

    return result
  }

  var formatter = new Intl.NumberFormat('th-TH', {
    style: 'decimal',
  })
  function picErrorCallback(e) {
    e.target.style.display = "none"
    setError(true)
  }
  function picLoadedCallback(e) {
    if(window.innerWidth > 767){
      e.target.style.display = "block"
      setError(false)
    }
  }
  function formatDate(timestr) {
    const date = new Date(timestr)
    const dd = parseInt(date.getDate()) > 9 ? parseInt(date.getDate()) : '0' + parseInt(date.getDate())
    const mm = (parseInt(date.getMonth()) + 1) > 9 ? (parseInt(date.getMonth()) + 1) : '0' + (parseInt(date.getMonth()) + 1)
    const yyyy = ('' + date.getFullYear()).substring(2, 4)
    const hh = parseInt(date.getHours()) > 9 ? parseInt(date.getHours()) : '0' + parseInt(date.getHours())
    const min = parseInt(date.getMinutes()) > 9 ? parseInt(date.getMinutes()) : '0' + parseInt(date.getMinutes())
    return dd + '/' + mm + '/' + yyyy + ' ' + hh + ':' + min + ' น.'
  }

  return (
    <div className={(obj.ServerStatus === 1 && obj.Data.diff !== 0) ? "admin-card-container green" : ((obj.ServerStatus === 1 && obj.Data.diff === 0) ? "admin-card-container red" : "admin-card-container grey")}>
      {obj.ServerStatus === 1 && <img className={error ? "company-logo noselect no-pic" : "company-logo noselect"} src={path+'/uimg?p='+encrypt(obj.CompanyRecord.Path + 'logo')} alt="" style={{ display: 'none' }} onError={picErrorCallback} onLoad={picLoadedCallback} />}
      {error && <div className="company-logo no-pic noselect">ไม่มีโลโก้</div>}
      <div className="details">
        {window.innerWidth < 767 ? (
          <div className="name-wrapper">
            <h3 className="name" title={obj?.CompanyRecord.CompanyName + ' ' + obj?.CompanyRecord.Branch}>{obj?.CompanyRecord.CompanyName + ' ' + obj?.CompanyRecord.Branch}</h3>
            <h4 className="time">{obj.Data.updated_time ? 'ข้อมูลวันที่ ' + formatDate(obj.Data.updated_time) : 'ไม่มีข้อมูล'}</h4>
          </div>
        ) : (
            <h3 className="name" title={obj?.CompanyRecord.CompanyName + ' ' + obj?.CompanyRecord.Branch}>{obj?.CompanyRecord.CompanyName + ' ' + obj?.CompanyRecord.Branch}</h3>
          )
        }
        <div className="centering">
          <h4 className="diff" title="ไฟล์ที่เพิ่มล่าสุด"><div className="l"><MdNoteAdd /></div>&nbsp;{formatter.format(obj?.Data.diff ? obj?.Data.diff : '')}<div className="r"><MdArrowDropUp /></div></h4>
          <h4 className="active" title="จำนวนผู้ใช้แอคทีฟ"><FaUserCheck />&nbsp;{formatter.format(obj?.Data.active_users ? obj?.Data.active_users : '')}</h4>
          <h4 className="total" title="ไฟล์ทั้งหมดในช่วงที่บันทึกล่าสุด"><MdHistory />&nbsp;{formatter.format(obj?.Data.total ? obj?.Data.total : '')}</h4>
          <h4 className="user" title="จำนวนผู้ใช้ทั้งหมด"><FaUsers />&nbsp;{formatter.format(obj?.Data.total_users ? obj?.Data.total_users : '')}</h4>
          {window.innerWidth > 767 && <h4 className="time">{obj.Data.updated_time ? 'ข้อมูลวันที่ ' + formatDate(obj.Data.updated_time) : 'ไม่มีข้อมูล'}</h4>}
        </div>
      </div>
    </div>
  )
}